
import { defineComponent, ref, PropType, watch, nextTick, onMounted } from 'vue';
import fUiClickOutside from './fUiClickOutside';

type Align = 'left' | 'center' | 'right';
type VAlign = 'top' | 'center' | 'bottom';

export default defineComponent({
  name: 'FUiPopup',
  emits: ['open', 'close'],
  directives: {
    fUiClickOutside,
  },
  props: {
    popupClass: {
      type: String,
    },
    align: {
      type: String as PropType<Align>,
      default: 'center',
    },
    verticalAlign: {
      type: String as PropType<VAlign>,
      default: 'bottom',
    },
    verticalMargin: {
      type: Number,
      default: 0,
    },
    padding: {
      type: String as PropType<'none' | 'small' | 'medium'>,
      validator: (v: string) => ['none', 'small', 'medium'].includes(v),
      default: 'small',
    },
    color: {
      type: String as PropType<'white' | 'black'>,
      validator: (v: string) => ['white', 'black'].includes(v),
      default: 'white',
    },
    fixed: Boolean,
    visible: Boolean, // Вручную показываем и скрываем попап
    black: Boolean,
    smallPaddings: Boolean,
    radius: Boolean,
    borderRadius: Boolean,
  },
  setup(props, { emit }) {
    const popupParentContainerDOM = ref<HTMLElement>();
    const popupContentDOM = ref<HTMLElement>();
    const popupLeft = ref<number>(0);
    const popupTop = ref<number>(0);
    let tryingToClosePopup = false;

    function openPopup() {
      if (!popupParentContainerDOM.value) {
        return;
      }

      setTimeout(() => {
        if (!popupParentContainerDOM.value || !popupContentDOM.value) {
          return;
        }

        const { left, right, top, width, height } =
          popupParentContainerDOM.value.getBoundingClientRect();

        if (props.align === 'left') {
          popupLeft.value = left;
        } else if (props.align === 'center') {
          popupLeft.value = right - width / 2 - popupContentDOM.value.offsetWidth / 2;
        }
        if (props.align === 'right') {
          popupLeft.value = right - popupContentDOM.value.offsetWidth;
        }

        if (props.verticalAlign === 'top') {
          popupTop.value = top - popupContentDOM.value.offsetHeight - props.verticalMargin;
        } else if (props.verticalAlign === 'center') {
          popupTop.value =
            top - height / 2 - popupContentDOM.value.offsetHeight / 2 - props.verticalMargin;
          popupLeft.value += props.align === 'left' ? width : -width;
        } else if (props.verticalAlign === 'bottom') {
          popupTop.value = top + height + props.verticalMargin;
        }

        if (!props.fixed) {
          popupTop.value += document.documentElement.scrollTop;
        }
      }, 0);

      emit('open');
    }

    function closePopup() {
      emit('close');
      tryingToClosePopup = true;
      nextTick(() => {
        tryingToClosePopup = false;
      });
    }

    onMounted(() => {
      if (props.visible) {
        openPopup();
      }
    });

    watch(
      () => props.visible,
      value => {
        if (value) {
          openPopup();
        } else if (!tryingToClosePopup) {
          closePopup();
        }
      },
    );

    return {
      popupParentContainerDOM,
      popupContentDOM,
      openPopup,
      closePopup,
      popupLeft,
      popupTop,
    };
  },
});
