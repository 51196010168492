import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f4580a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headerBlock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_container = _resolveComponent("header-container")!
  const _component_requests_header = _resolveComponent("requests-header")!
  const _component_requests_tabs = _resolveComponent("requests-tabs")!
  const _component_requests_table = _resolveComponent("requests-table")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_header_container, { type: _ctx.type }, null, 8, ["type"]),
      _createVNode(_component_requests_header, {
        class: "requestsHeader",
        transportations: _ctx.transportations,
        profile: _ctx.profile
      }, null, 8, ["transportations", "profile"]),
      (!_ctx.isFinishedTransportations)
        ? (_openBlock(), _createBlock(_component_requests_tabs, {
            key: 0,
            class: "requestsTabs",
            transportationsCountByType: _ctx.transportationsCountByType,
            transportationsCountLoading: _ctx.transportationsCountLoading
          }, null, 8, ["transportationsCountByType", "transportationsCountLoading"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_requests_table, {
      class: _normalizeClass(["requestsTable", {
      completed: _ctx.isFinishedTransportations,
      company: _ctx.profile?.company?.title,
    }]),
      transportationsLoading: _ctx.transportationsLoading,
      transportations: _ctx.transportations,
      loadTransportations: _ctx.loadTransportations,
      type: _ctx.type,
      profile: _ctx.profile
    }, null, 8, ["class", "transportationsLoading", "transportations", "loadTransportations", "type", "profile"]),
    _createVNode(_component_router_view)
  ], 64))
}