import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39f7b728"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loadingContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_table_tbody = _resolveComponent("f-table-tbody")!
  const _component_f_spinner = _resolveComponent("f-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_f_table_tbody, {
      ref: "tbodyDOM",
      class: _normalizeClass({ ['tbodyDOM_with-body']: _ctx.scrollOnContainer })
    }, {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["class"]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_f_spinner, { class: "loading" })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}