
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { TransportationsRouteTypes } from '@/router/constants';
import { RouteLocationNormalized, useRouter } from 'vue-router';
import IconFuraLogo from '@/components/UI/Icons/IconFuraLogo.vue';

export default defineComponent({
  name: 'Login',
  components: { IconFuraLogo },
  setup() {
    const phone = ref('');
    const code = ref('');
    const error = ref('');
    const formStep = ref(1);
    const pending = ref(false);
    const authStore = useAuthStore();
    const mask = computed(() => {
      return `+7 (###) ###-##-##`;
    });
    const redirectAfterLogin: RouteLocationNormalized | undefined = authStore.redirectAfterLogin;

    onMounted(() => {
      if (authStore.isAuthenticated) {
        const router = useRouter();

        router.push({
          name: 'home',
        });
      } else {
        authStore.getGuestToken();
      }
    });

    const { t } = useI18n({
      locale: 'ru',
      inheritLocale: true,
      messages: {
        ru: {
          stepPhone: 'Введите номер телефона',
          stepCode: 'Введите код из СМС',
          phone: 'Телефон',
          code: 'Код из СМС',
          enter: 'Продолжить',
          errors: {
            incorrectPhone: 'Введенный номер не зарегистрирован в системе',
            incorrectCode: 'Введен неверный код',
          },
        },
      },
    });
    // Check if phone data is correct and send data
    const onSubmit = async (evt: Event) => {
      evt.preventDefault();
      evt.stopPropagation();

      if (pending.value) {
        return false;
      }

      pending.value = true;

      if (formStep.value === 1 && phone.value.length > 0) {
        let res;
        try {
          res = await authStore.loginByPhone(phone.value.replace(/\D/g, ''));
        } catch (e) {
          phone.value = '';
          pending.value = false;
        }

        if (res) {
          formStep.value = 2;
        }
      } else if (formStep.value === 2) {
        let res;
        try {
          res = await authStore.approveBySMS(code.value);
        } catch (e) {
          code.value = '';
          pending.value = false;
        }

        if (res) {
          router.push(
            redirectAfterLogin && redirectAfterLogin.name !== 'login'
              ? redirectAfterLogin
              : {
                  name: 'transportations',
                  params: {
                    type: TransportationsRouteTypes.active,
                  },
                },
          );
        }
      }

      pending.value = false;
    };

    return {
      phone,
      code,
      formStep,
      onSubmit,
      pending,
      error,
      mask,
      t,
    };
  },
});
