import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.visible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "overlay",
              ref: "overlayDOM",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModalByClickOutside && _ctx.closeModalByClickOutside(...args)))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["modal", [_ctx.modalClass, _ctx.size]])
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2)
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}