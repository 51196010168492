
import { defineComponent, PropType, provide } from 'vue';
import { RouteLocation } from 'vue-router';

export default defineComponent({
  name: 'FTableTr',
  props: {
    to: {
      type: Object as PropType<RouteLocation>,
    },
  },
  setup(props) {
    provide('routeTo', props.to);
  },
});
