import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57c89944"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "logo" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_fura_logo = _resolveComponent("icon-fura-logo")!
  const _component_f_input = _resolveComponent("f-input")!
  const _component_f_button = _resolveComponent("f-button")!
  const _directive_mask = _resolveDirective("mask")!
  const _directive_only_phone_symbols = _resolveDirective("only-phone-symbols")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_icon_fura_logo)
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t(_ctx.formStep === 1 ? 'stepPhone' : 'stepCode')), 1),
      _createElementVNode("form", {
        class: "form",
        action: "#",
        onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        (_ctx.formStep === 1)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("label", _hoisted_5, _toDisplayString(_ctx.error), 1))
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_f_input, {
                type: "text",
                modelValue: _ctx.phone,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
                floatLabel: _ctx.t('phone'),
                focusOnMount: "",
                onKeydown: _withKeys(_ctx.onSubmit, ["enter"])
              }, null, 8, ["modelValue", "floatLabel", "onKeydown"]), [
                [_directive_mask, _ctx.mask],
                [_directive_only_phone_symbols]
              ])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.formStep === 2)
          ? (_openBlock(), _createBlock(_component_f_input, {
              key: 1,
              type: "text",
              modelValue: _ctx.code,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.code) = $event)),
              maxlength: "6",
              floatLabel: _ctx.t('code'),
              focusOnMount: "",
              onKeydown: _withKeys(_ctx.onSubmit, ["enter"])
            }, null, 8, ["modelValue", "floatLabel", "onKeydown"]))
          : _createCommentVNode("", true),
        _createVNode(_component_f_button, {
          class: "submit",
          onClick: _ctx.onSubmit,
          disabled: _ctx.formStep === 1 ? !_ctx.phone || _ctx.phone.length !== 18 : !_ctx.code,
          loading: _ctx.pending,
          label: _ctx.t('enter')
        }, null, 8, ["onClick", "disabled", "loading", "label"])
      ], 32)
    ])
  ]))
}