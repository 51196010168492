export default {
  developmentOffline: false,
  version: process.env.VUE_APP_VERSION,
  app: {
    productionHostname: 'verify.gofura.com',
    development: process.env.NODE_ENV !== 'production',
    name: 'Fura Verification',
    title: 'Fura.Verification',
    globalAuthRequired: false,
    stand: process.env.VUE_APP_STAND,
    url: {
      api: process.env.VUE_APP_API_V3_VERIFICATION_URL,
      apiTrace: process.env.VUE_APP_API_TRACE_URL,
      apiSumSub: process.env.VUE_APP_API_SUMSUB_URL,
      apiAuth: process.env.VUE_APP_API_V3_AUTH_URL,
      apiAlmanac: process.env.VUE_APP_API_ALMANAC_URL,
      apiErp: process.env.VUE_APP_API_ERP_URL,
      urlErp: process.env.VUE_APP_ERP_URL,
      assets: '',
    },
    services: {
      googleMaps: process.env.VUE_APP_ERP_GOOGLE_MAPS_KEY,
      mapBoxToken: process.env.VUE_APP_TRACE_MAP_BOX_TOKEN,
      yandex_metrica_id: process.env.VUE_APP_TRACE_YANDEX_METRICA_ID,
    },
  },
};
