import { withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-694a00cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "checked"]
const _hoisted_2 = { class: "f-checkbox-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon_check = _resolveComponent("f-icon-check")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["f-checkbox", { 'f-checkbox_disabled': _ctx.disabled }]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleCheckbox && _ctx.toggleCheckbox(...args)))
  }, [
    _createElementVNode("input", _mergeProps({ type: "checkbox" }, { ..._ctx.$props, ..._ctx.$attrs }, {
      disabled: _ctx.disabled,
      class: "f-checkbox-input",
      checked: _ctx.modelValue,
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }), null, 16, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(["f-checkbox-icon-container", { 'f-checkbox-icon-container_active': !!_ctx.modelValue }])
    }, [
      (_ctx.hasIconSlot)
        ? _renderSlot(_ctx.$slots, "icon", { key: 0 }, undefined, true)
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: _normalizeClass(["f-checkbox-icon", { 'f-checkbox-icon_active': !!_ctx.modelValue }])
      }, [
        (_ctx.modelValue)
          ? (_openBlock(), _createBlock(_component_f_icon_check, { key: 0 }))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}