// Constants or enums for parameters

export enum TransportationsRouteTypes {
  active = 'active',
  completed = 'completed',
}

export enum TransportationModalRouteTabs {
  monitoring = 'monitoring',
  driverAndVehicle = 'driverAndVehicle',
  cargo = 'cargo',
  expeditor = 'expeditor',
}
