import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Объект произвольной структуры */
  Any: any;
  /** Продолжительность в мс */
  Duration: any;
  /** Локальное время (без привязки к таймзоне) */
  LocalDateTime: any;
  /** Таймштамп RFC3339 */
  Time: any;
};


/** Адрес */
export type Address = {
  __typename?: 'Address';
  /** Адрес одной строкой */
  formatted: Scalars['String'];
  /** Населенный пункт */
  locality: Scalars['String'];
};


/** Груз */
export type Cargo = {
  __typename?: 'Cargo';
  /** Тип груза */
  cargoType: Scalars['String'];
  /** Количество паллет */
  volumeInPallets?: Maybe<Scalars['Int']>;
  /** Объем, м³ */
  volumeInCubometres?: Maybe<Scalars['Float']>;
  /** Вес */
  bruttoInTonns?: Maybe<Scalars['Float']>;
};

/** Грузоперевозчик */
export type Carrier = {
  __typename?: 'Carrier';
  /** Id клиента */
  id: Scalars['ID'];
  /** Название */
  title: Scalars['String'];
};

/** Клиент */
export type Client = {
  __typename?: 'Client';
  /** Id клиента */
  id: Scalars['ID'];
  /** Название */
  title: Scalars['String'];
};

/** Статус водителя для клиента */
export enum ClientDriverStatus {
  /** Готовится */
  Preparing = 'PREPARING',
  /** Едет на погрузку */
  OnWayToLoading = 'ON_WAY_TO_LOADING',
  /** Едет на разгрузку */
  OnWayToUnloading = 'ON_WAY_TO_UNLOADING',
  /** Приехал на погрузку */
  ArrivedToLoading = 'ARRIVED_TO_LOADING',
  /** Приехал на разгрузку */
  ArrivedToUnloading = 'ARRIVED_TO_UNLOADING',
  /** Завершено */
  Completed = 'COMPLETED',
  /** Не в сети/не отслеживается */
  NoInitData = 'NO_INIT_DATA'
}

/** Компания */
export type Company = {
  __typename?: 'Company';
  /** ID компании */
  id: Scalars['ID'];
  /** Название */
  title: Scalars['String'];
};

/** Координаты */
export type Coordinates = {
  __typename?: 'Coordinates';
  /** Широта */
  lat: Scalars['Float'];
  /** Долгота */
  lng: Scalars['Float'];
};

/** Данные о текущем пользователе */
export type CurrentUserProfile = {
  __typename?: 'CurrentUserProfile';
  /** ID пользователя */
  id: Scalars['ID'];
  /** Имя или название */
  title: Scalars['String'];
  /** Роли */
  roles: Array<Scalars['String']>;
  /** Id компании */
  companyId: Scalars['String'];
  /** Компания */
  company: Company;
};

/** Водитель */
export type Driver = {
  __typename?: 'Driver';
  /** Id водителя */
  id: Scalars['ID'];
  /** Имя */
  fullName: Scalars['String'];
  /** Номера телефона */
  phoneNumbers: Array<Scalars['String']>;
  /** Статус на основе телеметрии */
  status: DriverActivityStatus;
  lastLocation?: Maybe<Location>;
};

/** Статус водителя */
export enum DriverActivityStatus {
  /** Статус не определен */
  DriverActivityStatusUndefined = 'DRIVER_ACTIVITY_STATUS_UNDEFINED',
  /** Водитель оффлайн */
  DriverActivityStatusOffline = 'DRIVER_ACTIVITY_STATUS_OFFLINE',
  /** Водитель остановился */
  DriverActivityStatusStandsStill = 'DRIVER_ACTIVITY_STATUS_STANDS_STILL',
  /** Водитель в пути */
  DriverActivityStatusRides = 'DRIVER_ACTIVITY_STATUS_RIDES'
}


/** Экспедитор */
export type Expeditor = {
  __typename?: 'Expeditor';
  /** Имя */
  fullName: Scalars['String'];
  /** Номер телефона */
  phoneNumber: Scalars['String'];
};

/** Справочник */
export type Glossary = {
  __typename?: 'Glossary';
  /** Ключ справочника */
  id: Scalars['ID'];
  /** Элементы справочника */
  elements: Array<GlossaryElement>;
};

/** Элемент справочника */
export type GlossaryElement = {
  __typename?: 'GlossaryElement';
  /** Ключ элемента */
  key: Scalars['String'];
  /** Название */
  title: Scalars['String'];
  /** Содержимое (json) */
  content: Scalars['Any'];
  /** Порядковый номер для сортировки */
  sortingNumber: Scalars['Int'];
};


/** Данные позиции */
export type Location = {
  __typename?: 'Location';
  /** Время, когда позиция была получена */
  occurredAt?: Maybe<Scalars['Time']>;
  /** Долгота */
  coordinates: Coordinates;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Обновить статус точки до Завершено */
  UpdateTargetStatusToCompletedInput?: Maybe<Transportation>;
};


export type MutationUpdateTargetStatusToCompletedInputArgs = {
  input: UpdateTargetStatusToCompletedInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Total number of items */
  totalCount: Scalars['Int'];
  /** When paginating backwards, the cursor to continue. String or base64 encoded */
  startCursor?: Maybe<Scalars['String']>;
  /** When pg forwards, the cursor to continue. String or base64 encoded */
  endCursor?: Maybe<Scalars['String']>;
};

/** Запросы */
export type Query = {
  __typename?: 'Query';
  /** Информация об аккаунте пользователя */
  me?: Maybe<CurrentUserProfile>;
  /** Получить справочник */
  glossary: Glossary;
  /** Получить справочник */
  glossaries: Array<Glossary>;
  /** Получение списка перевозок */
  transportations?: Maybe<TransportationsConnection>;
  /** Получение сводной статистики по списку перевозок */
  transportationsSummary?: Maybe<TransportationsSummary>;
  /** Получение конкретной перевозки */
  transportation?: Maybe<Transportation>;
};


/** Запросы */
export type QueryGlossaryArgs = {
  id: Scalars['String'];
};


/** Запросы */
export type QueryGlossariesArgs = {
  ids: Array<Scalars['String']>;
};


/** Запросы */
export type QueryTransportationsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
};


/** Запросы */
export type QueryTransportationArgs = {
  id: Scalars['ID'];
};

/** Заявка */
export type Request = {
  __typename?: 'Request';
  /** Id заявки */
  id: Scalars['String'];
  /** Короткий номер */
  shortId: Scalars['String'];
  /** Внешний номер */
  externalNumber: Scalars['String'];
  /** Груз */
  cargo: Cargo;
  /** Ответственный логист */
  responsibleExpeditor: Expeditor;
  /** Id клиента */
  clientId: Scalars['ID'];
  /** Клиент */
  client: Client;
};

/** Роли в портале */
export enum Role {
  /** Логист Фуры */
  PortalFuraExpeditor = 'Portal_FuraExpeditor',
  /** Логист клиента */
  PortalClientExpeditor = 'Portal_ClientExpeditor',
  /** Логист перевозчика */
  PortalCarrierExpeditor = 'Portal_CarrierExpeditor',
  /** Админ */
  PortalSuperAdmin = 'Portal_SuperAdmin'
}

/** Цель */
export type Target = {
  __typename?: 'Target';
  /** Id цели */
  id: Scalars['ID'];
  /** Тип цели */
  type: TargetType;
  /** Адрес */
  address: Address;
  /** Флаг, считается ли задержка водителя опозданием в момент выполнения цели (если цель не завершена) */
  driverIsLate: Scalars['Boolean'];
  /** Флаг, считается ли задержка водителя опозданием по факту прибытия (если цель завершена) */
  driverWasLate: Scalars['Boolean'];
  /** Расчетное время прибытия */
  estimatedArriveAt?: Maybe<Scalars['Time']>;
  /** Плановое время прибытия */
  plannedArriveAt: Scalars['Time'];
  /** Фактическое время прибытия */
  actualArriveAt?: Maybe<Scalars['Time']>;
  /** Расчетное время прибытия локальное */
  estimatedArriveAtLocal?: Maybe<Scalars['LocalDateTime']>;
  /** Плановое время прибытия локальное */
  plannedArriveAtLocal: Scalars['LocalDateTime'];
  /** Фактическое время прибытия локальное */
  actualArriveAtLocal?: Maybe<Scalars['LocalDateTime']>;
  /** Статус цели */
  targetStatusDetails: TargetStatusDetails;
  /** Координаты */
  coordinates: Coordinates;
  /** Грузополучатель */
  consignee?: Maybe<Scalars['String']>;
  /** Величина опоздания */
  delay?: Maybe<Scalars['Duration']>;
};

/** Статус цели в перевозке */
export enum TargetStatus {
  /** Статус не определен */
  TargetStatusUndefined = 'TARGET_STATUS_UNDEFINED',
  /** Водитель в пути к цели перевозки. Автоматика считает так, если водитель снаружи соответствующей геозоны */
  TargetStatusDriverOnWay = 'TARGET_STATUS_DRIVER_ON_WAY',
  /** Водитель прибыл в место назначение. Автоматика считает так, если водитель внутри соответствующей геозоны */
  TargetStatusDriverArrived = 'TARGET_STATUS_DRIVER_ARRIVED',
  /** Водитель выполнил (ручное или автоматическое) условия завершения цели. Автоматика считает так, если водитель покинул соответствующую геозону */
  TargetStatusCompleted = 'TARGET_STATUS_COMPLETED'
}

/** Детали статуса цели */
export type TargetStatusDetails = {
  __typename?: 'TargetStatusDetails';
  /** Статус цели перевозки */
  targetStatus: TargetStatus;
  /** Время обновления статуса */
  updatedAt: Scalars['Time'];
  /** Кто обновил статус */
  updatedBy?: Maybe<UserProfile>;
};

/** Тип цели */
export enum TargetType {
  /** Тип не определен */
  TargetTypeUndefined = 'TARGET_TYPE_UNDEFINED',
  /** Погрузка */
  TargetTypeLoading = 'TARGET_TYPE_LOADING',
  /** Разгрузка */
  TargetTypeUnloading = 'TARGET_TYPE_UNLOADING'
}


/** Статус трейсинга / Fura Trace */
export enum TraceStatus {
  /** Статус не определен */
  TraceStatusUndefined = 'TRACE_STATUS_UNDEFINED',
  /**
   * Перевозка предстоит в будущем (выставляется за N часов до прибытия к первой цели перевозки, если перевозка ACCEPTED)
   * При выставлении статуса происходит включение трейсинга для перевозки
   */
  TraceStatusUpcoming = 'TRACE_STATUS_UPCOMING',
  /** Выполнение перевозки должно начаться, но от водителя не поступала телеметрия и/или не известна ETA до первой цели */
  TraceStatusNoInitData = 'TRACE_STATUS_NO_INIT_DATA',
  /** Выполнение перевозки началась */
  TraceStatusTargetsInProgress = 'TRACE_STATUS_TARGETS_IN_PROGRESS',
  /**
   * Выполнение перевозки в рамках трейсинга завершено. Соответствует завершающему статусу перевозки
   * При выставлении статуса происходит выключение трейсинга для перевозки
   */
  TraceStatusCompleted = 'TRACE_STATUS_COMPLETED'
}

/** Статус трейсинга / Fura Trace */
export enum TracingStatus {
  /** Статус не определен */
  TracingStatusUndefined = 'TRACING_STATUS_UNDEFINED',
  /**
   * Перевозка предстоит в будущем (выставляется за N часов до прибытия к первой цели перевозки, если перевозка ACCEPTED)
   * При выставлении статуса происходит включение трейсинга для перевозки
   */
  TracingStatusUpcoming = 'TRACING_STATUS_UPCOMING',
  /** Выполнение перевозки началась, но водитель опаздывает к текущей цели перевозки */
  TracingStatusLate = 'TRACING_STATUS_LATE',
  /** Выполнение перевозки началась и водитель успевает к текущей цели перевозки */
  TracingStatusInTime = 'TRACING_STATUS_IN_TIME',
  /**
   * Выполнение перевозки в рамках трейсинга завершено. Соответствует завершающему статусу перевозки
   * При выставлении статуса происходит выключение трейсинга для перевозки
   */
  TracingStatusCompleted = 'TRACING_STATUS_COMPLETED'
}

/** Трек */
export type Track = {
  __typename?: 'Track';
  /**
   * Путь, encoded polyline
   * https://developers.google.com/maps/documentation/utilities/polylinealgorithm
   */
  path: Scalars['String'];
  /** Позиция водителя */
  driverLocation?: Maybe<Location>;
};

/** Перевозка */
export type Transportation = {
  __typename?: 'Transportation';
  /** Id перевозки */
  id: Scalars['ID'];
  /** Заявка */
  request: Request;
  /** Статус водителя для клиента (в UI) */
  clientStatus: ClientDriverStatus;
  /** Статус перевозки */
  statusDetails: TransportationStatusDetails;
  /** Водитель */
  driver: Driver;
  /** ТС */
  vehicles: Array<Vehicle>;
  /** Цели */
  targets: Array<Target>;
  /** Включено ли автоматическое отслеживание */
  tracingIsEnabled: Scalars['Boolean'];
  /** Зафиксировано опоздание */
  driverIsLate: Scalars['Boolean'];
  /** Флаг, зафиксировано ли опоздание хотя бы к одной из целей перевозки по факту прибытия (когда перевозка завершена) */
  tardinessRecorded: Scalars['Boolean'];
  /** Дата создания */
  createdAt: Scalars['Time'];
  /** Дата обновления */
  updatedAt: Scalars['Time'];
  /** Текущая цель */
  currentTarget?: Maybe<Target>;
  /** Начальная цель */
  start: Target;
  /** Конечная цель */
  finish: Target;
  /** Трек водителя */
  track?: Maybe<Track>;
};

/** An edge in a connection */
export type TransportationEdge = {
  __typename?: 'TransportationEdge';
  /** The item at the end of the edge */
  node: Transportation;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Статус перевозки */
export enum TransportationStatus {
  /** Статус не определен */
  TransportationStatusUndefined = 'TRANSPORTATION_STATUS_UNDEFINED',
  /** Перевозка готова к тому, что бы ее взял водитель */
  TransportationStatusReady = 'TRANSPORTATION_STATUS_READY',
  /** Назначенный водитель подтвердил, что будет выполнять перевозку */
  TransportationStatusAccepted = 'TRANSPORTATION_STATUS_ACCEPTED',
  /** Водитель начал выполнение перевозки и выполняет текущую цель перевозки */
  TransportationStatusTargetInProgress = 'TRANSPORTATION_STATUS_TARGET_IN_PROGRESS',
  /** Перевозка отменена по каким либо причинам */
  TransportationStatusCancelled = 'TRANSPORTATION_STATUS_CANCELLED',
  /** Все цели на маршруте завершены и/или на холде */
  TransportationStatusCompleted = 'TRANSPORTATION_STATUS_COMPLETED'
}

/** Детали текущего статуса перевозки */
export type TransportationStatusDetails = {
  __typename?: 'TransportationStatusDetails';
  /** Статус перевозки */
  transportationStatus: TransportationStatus;
  /** Статус отслеживания */
  traceStatus: TraceStatus;
};

/** Курсор по списку перевозок */
export type TransportationsConnection = {
  __typename?: 'TransportationsConnection';
  /** A list of edges */
  edges: Array<TransportationEdge>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
};

/** Сводка по статусам заявок */
export type TransportationsSummary = {
  __typename?: 'TransportationsSummary';
  /** Опоздание */
  late: Scalars['Int'];
  /** Не отслеживается */
  noInitData: Scalars['Int'];
  /** Во время */
  inTime: Scalars['Int'];
  /** Предстоящие */
  upcoming: Scalars['Int'];
  /** Завершенные */
  completed: Scalars['Int'];
};

export type UpdateTargetStatusToCompletedInput = {
  transportationId: Scalars['String'];
  targetId: Scalars['String'];
  actualArriveAtLocal?: Maybe<Scalars['LocalDateTime']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  id?: Maybe<Scalars['ID']>;
  fullName: Scalars['String'];
};

/** ТС */
export type Vehicle = {
  __typename?: 'Vehicle';
  /** Id ТС */
  id: Scalars['ID'];
  /** Типы кузова */
  typesOfBody: Array<Scalars['String']>;
  /** Марка */
  brand: Scalars['String'];
  /** Гос. номер */
  numberPlate: Scalars['String'];
  /** Тип ТС */
  type: Scalars['String'];
};

export const StatusDetail = gql`
    fragment StatusDetail on TransportationStatusDetails {
  traceStatus
  transportationStatus
}
    `;
export const TargetShort = gql`
    fragment TargetShort on Target {
  actualArriveAtLocal
  address {
    formatted
    locality
  }
  consignee
  driverIsLate
  driverWasLate
  estimatedArriveAtLocal
  id
  plannedArriveAtLocal
  targetStatusDetails {
    targetStatus
  }
  delay
  type
}
    `;
export const ShortTransportation = gql`
    fragment ShortTransportation on Transportation {
  id
  clientStatus
  tracingIsEnabled
  statusDetails {
    ...StatusDetail
  }
  tardinessRecorded
  driver {
    status
    lastLocation {
      occurredAt
    }
  }
  request {
    id
    shortId
    externalNumber
    client {
      title
    }
  }
  currentTarget {
    ...TargetShort
  }
  start {
    ...TargetShort
  }
  finish {
    ...TargetShort
  }
  targets {
    consignee
  }
  createdAt
  updatedAt
}
    ${StatusDetail}
${TargetShort}`;
export const Request = gql`
    fragment Request on Request {
  id
  shortId
  externalNumber
  cargo {
    cargoType
    volumeInPallets
    volumeInCubometres
    bruttoInTonns
  }
  responsibleExpeditor {
    fullName
    phoneNumber
  }
  clientId
  client {
    id
    title
  }
}
    `;
export const Driver = gql`
    fragment Driver on Driver {
  fullName
  id
  phoneNumbers
  status
}
    `;
export const Vehicle = gql`
    fragment Vehicle on Vehicle {
  brand
  id
  numberPlate
  type
  typesOfBody
}
    `;
export const Target = gql`
    fragment Target on Target {
  actualArriveAt
  actualArriveAtLocal
  address {
    formatted
    locality
  }
  consignee
  driverIsLate
  driverWasLate
  estimatedArriveAt
  estimatedArriveAtLocal
  id
  coordinates {
    lat
    lng
  }
  plannedArriveAt
  plannedArriveAtLocal
  targetStatusDetails {
    targetStatus
    updatedAt
    updatedBy {
      id
      fullName
    }
  }
  delay
  type
}
    `;
export const Track = gql`
    fragment Track on Track {
  path
  driverLocation {
    occurredAt
    coordinates {
      lat
      lng
    }
  }
}
    `;
export const Transportation = gql`
    fragment Transportation on Transportation {
  id
  tardinessRecorded
  request {
    ...Request
  }
  clientStatus
  statusDetails {
    ...StatusDetail
  }
  driver {
    ...Driver
  }
  vehicles {
    ...Vehicle
  }
  tracingIsEnabled
  targets {
    ...Target
  }
  start {
    ...Target
  }
  finish {
    ...Target
  }
  track {
    ...Track
  }
  currentTarget {
    ...Target
  }
  createdAt
  updatedAt
}
    ${Request}
${StatusDetail}
${Driver}
${Vehicle}
${Target}
${Track}`;
export const TransportationsSummary = gql`
    fragment TransportationsSummary on TransportationsSummary {
  late
  inTime
  upcoming
  noInitData
}
    `;
export const UserProfile = gql`
    fragment UserProfile on CurrentUserProfile {
  id
  title
  roles
  companyId
  company {
    id
    title
  }
}
    `;
export const GetGlossaries = gql`
    query getGlossaries($ids: [String!]!) {
  glossaries(ids: $ids) {
    elements {
      content
      key
      sortingNumber
      title
    }
    id
  }
}
    `;
export const GetMe = gql`
    query getMe {
  me {
    ...UserProfile
  }
}
    ${UserProfile}`;
export const GetTransportation = gql`
    query getTransportation($id: ID!) {
  transportation(id: $id) {
    ...Transportation
  }
}
    ${Transportation}`;
export const GetTransportationDriverCurrentPosition = gql`
    query getTransportationDriverCurrentPosition($id: ID!) {
  transportation(id: $id) {
    track {
      driverLocation {
        coordinates {
          lat
          lng
        }
      }
    }
  }
}
    `;
export const GetTransportations = gql`
    query getTransportations($first: Int, $after: String, $completed: Boolean!) {
  transportations(first: $first, after: $after, completed: $completed) {
    edges {
      node {
        ...ShortTransportation
      }
      cursor
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${ShortTransportation}`;
export const GetTransportationsSummary = gql`
    query getTransportationsSummary {
  transportationsSummary {
    ...TransportationsSummary
  }
}
    ${TransportationsSummary}`;
export const UpdateTargetStatusToCompleted = gql`
    mutation updateTargetStatusToCompleted($data: UpdateTargetStatusToCompletedInput!) {
  UpdateTargetStatusToCompletedInput(input: $data) {
    ...Transportation
  }
}
    ${Transportation}`;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type DriverFragment = (
  { __typename?: 'Driver' }
  & Pick<Driver, 'fullName' | 'id' | 'phoneNumbers' | 'status'>
);

export type RequestFragment = (
  { __typename?: 'Request' }
  & Pick<Request, 'id' | 'shortId' | 'externalNumber' | 'clientId'>
  & { cargo: (
    { __typename?: 'Cargo' }
    & Pick<Cargo, 'cargoType' | 'volumeInPallets' | 'volumeInCubometres' | 'bruttoInTonns'>
  ), responsibleExpeditor: (
    { __typename?: 'Expeditor' }
    & Pick<Expeditor, 'fullName' | 'phoneNumber'>
  ), client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'title'>
  ) }
);

export type ShortTransportationFragment = (
  { __typename?: 'Transportation' }
  & Pick<Transportation, 'id' | 'clientStatus' | 'tracingIsEnabled' | 'tardinessRecorded' | 'createdAt' | 'updatedAt'>
  & { statusDetails: (
    { __typename?: 'TransportationStatusDetails' }
    & StatusDetailFragment
  ), driver: (
    { __typename?: 'Driver' }
    & Pick<Driver, 'status'>
    & { lastLocation?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'occurredAt'>
    )> }
  ), request: (
    { __typename?: 'Request' }
    & Pick<Request, 'id' | 'shortId' | 'externalNumber'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'title'>
    ) }
  ), currentTarget?: Maybe<(
    { __typename?: 'Target' }
    & TargetShortFragment
  )>, start: (
    { __typename?: 'Target' }
    & TargetShortFragment
  ), finish: (
    { __typename?: 'Target' }
    & TargetShortFragment
  ), targets: Array<(
    { __typename?: 'Target' }
    & Pick<Target, 'consignee'>
  )> }
);

export type StatusDetailFragment = (
  { __typename?: 'TransportationStatusDetails' }
  & Pick<TransportationStatusDetails, 'traceStatus' | 'transportationStatus'>
);

export type TargetFragment = (
  { __typename?: 'Target' }
  & Pick<Target, 'actualArriveAt' | 'actualArriveAtLocal' | 'consignee' | 'driverIsLate' | 'driverWasLate' | 'estimatedArriveAt' | 'estimatedArriveAtLocal' | 'id' | 'plannedArriveAt' | 'plannedArriveAtLocal' | 'delay' | 'type'>
  & { address: (
    { __typename?: 'Address' }
    & Pick<Address, 'formatted' | 'locality'>
  ), coordinates: (
    { __typename?: 'Coordinates' }
    & Pick<Coordinates, 'lat' | 'lng'>
  ), targetStatusDetails: (
    { __typename?: 'TargetStatusDetails' }
    & Pick<TargetStatusDetails, 'targetStatus' | 'updatedAt'>
    & { updatedBy?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'fullName'>
    )> }
  ) }
);

export type TargetShortFragment = (
  { __typename?: 'Target' }
  & Pick<Target, 'actualArriveAtLocal' | 'consignee' | 'driverIsLate' | 'driverWasLate' | 'estimatedArriveAtLocal' | 'id' | 'plannedArriveAtLocal' | 'delay' | 'type'>
  & { address: (
    { __typename?: 'Address' }
    & Pick<Address, 'formatted' | 'locality'>
  ), targetStatusDetails: (
    { __typename?: 'TargetStatusDetails' }
    & Pick<TargetStatusDetails, 'targetStatus'>
  ) }
);

export type TrackFragment = (
  { __typename?: 'Track' }
  & Pick<Track, 'path'>
  & { driverLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'occurredAt'>
    & { coordinates: (
      { __typename?: 'Coordinates' }
      & Pick<Coordinates, 'lat' | 'lng'>
    ) }
  )> }
);

export type TransportationFragment = (
  { __typename?: 'Transportation' }
  & Pick<Transportation, 'id' | 'tardinessRecorded' | 'clientStatus' | 'tracingIsEnabled' | 'createdAt' | 'updatedAt'>
  & { request: (
    { __typename?: 'Request' }
    & RequestFragment
  ), statusDetails: (
    { __typename?: 'TransportationStatusDetails' }
    & StatusDetailFragment
  ), driver: (
    { __typename?: 'Driver' }
    & DriverFragment
  ), vehicles: Array<(
    { __typename?: 'Vehicle' }
    & VehicleFragment
  )>, targets: Array<(
    { __typename?: 'Target' }
    & TargetFragment
  )>, start: (
    { __typename?: 'Target' }
    & TargetFragment
  ), finish: (
    { __typename?: 'Target' }
    & TargetFragment
  ), track?: Maybe<(
    { __typename?: 'Track' }
    & TrackFragment
  )>, currentTarget?: Maybe<(
    { __typename?: 'Target' }
    & TargetFragment
  )> }
);

export type TransportationsSummaryFragment = (
  { __typename?: 'TransportationsSummary' }
  & Pick<TransportationsSummary, 'late' | 'inTime' | 'upcoming' | 'noInitData'>
);

export type UserProfileFragment = (
  { __typename?: 'CurrentUserProfile' }
  & Pick<CurrentUserProfile, 'id' | 'title' | 'roles' | 'companyId'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'title'>
  ) }
);

export type VehicleFragment = (
  { __typename?: 'Vehicle' }
  & Pick<Vehicle, 'brand' | 'id' | 'numberPlate' | 'type' | 'typesOfBody'>
);

export type GetGlossariesQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetGlossariesQuery = (
  { __typename?: 'Query' }
  & { glossaries: Array<(
    { __typename?: 'Glossary' }
    & Pick<Glossary, 'id'>
    & { elements: Array<(
      { __typename?: 'GlossaryElement' }
      & Pick<GlossaryElement, 'content' | 'key' | 'sortingNumber' | 'title'>
    )> }
  )> }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'CurrentUserProfile' }
    & UserProfileFragment
  )> }
);

export type GetTransportationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTransportationQuery = (
  { __typename?: 'Query' }
  & { transportation?: Maybe<(
    { __typename?: 'Transportation' }
    & TransportationFragment
  )> }
);

export type GetTransportationDriverCurrentPositionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTransportationDriverCurrentPositionQuery = (
  { __typename?: 'Query' }
  & { transportation?: Maybe<(
    { __typename?: 'Transportation' }
    & { track?: Maybe<(
      { __typename?: 'Track' }
      & { driverLocation?: Maybe<(
        { __typename?: 'Location' }
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<Coordinates, 'lat' | 'lng'>
        ) }
      )> }
    )> }
  )> }
);

export type GetTransportationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
}>;


export type GetTransportationsQuery = (
  { __typename?: 'Query' }
  & { transportations?: Maybe<(
    { __typename?: 'TransportationsConnection' }
    & { edges: Array<(
      { __typename?: 'TransportationEdge' }
      & Pick<TransportationEdge, 'cursor'>
      & { node: (
        { __typename?: 'Transportation' }
        & ShortTransportationFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    ) }
  )> }
);

export type GetTransportationsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTransportationsSummaryQuery = (
  { __typename?: 'Query' }
  & { transportationsSummary?: Maybe<(
    { __typename?: 'TransportationsSummary' }
    & TransportationsSummaryFragment
  )> }
);

export type UpdateTargetStatusToCompletedMutationVariables = Exact<{
  data: UpdateTargetStatusToCompletedInput;
}>;


export type UpdateTargetStatusToCompletedMutation = (
  { __typename?: 'Mutation' }
  & { UpdateTargetStatusToCompletedInput?: Maybe<(
    { __typename?: 'Transportation' }
    & TransportationFragment
  )> }
);
