import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import polyline from '@mapbox/polyline';
import { Map, Marker } from 'mapbox-gl';
import { Target, TargetStatus, Coordinates } from '@/api/generated/portal_api';

export function drawPlaces(map: Map, targets: Target[]): Marker[] {
  return targets.map((target, index) => {
    // create a HTML element for each feature
    const el = document.createElement('div');
    el.className = 'mapbox-marker';
    if (target.targetStatusDetails.targetStatus === TargetStatus.TargetStatusCompleted) {
      el.className = el.className + ' grey';
    }
    el.innerText = `${index + 1}`;

    const { locality, formatted } = target.address;

    return new mapboxgl.Marker(el, {
      offset: [0, -30],
    })
      .setLngLat([target.coordinates.lng, target.coordinates.lat])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }).setHTML(`<h3>${locality}</h3><br />${formatted}`),
      )
      .addTo(map);
  });
}

export function drawCurrentPlace(map: Map, coordinates: Coordinates): Marker {
  const el = document.createElement('div');
  el.className = 'mapbox-current-pos-marker';

  return new mapboxgl.Marker(el, {
    offset: [0, 0],
  })
    .setLngLat([coordinates.lng, coordinates.lat])
    .addTo(map);
}

export function drawLines(
  map: Map,
  pathStringPolyline: string,
  color: string,
  sourceId = 'route',
): void {
  const points = polyline.toGeoJSON(pathStringPolyline).coordinates;

  map.addSource(sourceId, {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: points,
      },
    },
  });

  map.addLayer({
    id: sourceId,
    type: 'line',
    source: sourceId,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': color,
      'line-width': 6,
    },
  });
}

export function centerMap(
  map: Map,
  targets: Target[],
  currentPosCoords?: Coordinates,
  pathStringPolyline?: string,
): void {
  let points = targets.map(({ coordinates }) => [coordinates.lng, coordinates.lat]);

  if (currentPosCoords) {
    points.push([currentPosCoords.lng, currentPosCoords.lat]);
  }
  if (pathStringPolyline) {
    const pathPoints = polyline.toGeoJSON(pathStringPolyline).coordinates;
    points = [...points, ...pathPoints];
  }
  const bounds = points.reduce(
    (bounds, coord) => bounds.extend(coord),
    new mapboxgl.LngLatBounds(points[0], points[0]),
  );

  map.fitBounds(bounds, {
    padding: 40,
    duration: 0,
  });

  map.flyTo({ center: map.getCenter(), zoom: map.getZoom() });
}
