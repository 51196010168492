import { defineStore } from 'pinia';
import { apiTrace } from '@/api/trace';
import { useAuthStore } from '@/stores/auth';
import { print } from 'graphql';
import { useToast } from 'vue-toastification';
import { apiGraphqlCallWrapper } from './apiWrapper';
import { GetMe, GetMeQuery, UserProfileFragment } from '@/api/generated/portal_api';

const toast = useToast();

type State = {
  profileLoading: boolean;
  profile?: UserProfileFragment;
};

export const useProfileStore = defineStore({
  id: 'profile',

  state: (): State => ({
    profileLoading: false,
    profile: undefined,
  }),

  actions: {
    async getProfile() {
      this.profileLoading = true;
      const res = await apiGraphqlCallWrapper<GetMeQuery>(
        apiTrace,
        {
          url: 'query',
          data: {
            query: print(GetMe),
          },
        },
        {
          onAnyError: () => {
            toast.error('Не удалось получить данные профиля');
            const authStore = useAuthStore();
            authStore.logout();
          },
        },
      );
      this.profile = res.me;
      this.profileLoading = false;
    },

    reset() {
      this.profile = undefined;
    },
  },
});
