import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_requests = _resolveComponent("requests")!

  return (_openBlock(), _createBlock(_component_requests, {
    transportationsLoading: _ctx.transportationsLoading,
    glossaries: _ctx.glossaries,
    transportations: _ctx.transportations,
    loadTransportations: _ctx.loadTransportations,
    silentReloadTransportationsCount: _ctx.silentReloadTransportationsCount,
    silentReloadTransportations: _ctx.silentReloadTransportations,
    transportationsCountByType: _ctx.transportationsCountByType,
    transportationsCountLoading: _ctx.transportationsCountLoading,
    loadTransportationsCount: _ctx.loadTransportationsCount,
    profile: _ctx.profile,
    type: _ctx.type
  }, null, 8, ["transportationsLoading", "glossaries", "transportations", "loadTransportations", "silentReloadTransportationsCount", "silentReloadTransportations", "transportationsCountByType", "transportationsCountLoading", "loadTransportationsCount", "profile", "type"]))
}