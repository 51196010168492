
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconFuraLogo',
  props: {
    width: {
      type: Number,
      default: 90,
    },
    color: {
      type: String,
      default: 'black',
    },
  },
});
