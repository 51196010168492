import { AxiosInstance } from 'axios';
import config from '@/../config/config';
import { registerAPI } from './API';

export const apiAuth: AxiosInstance = registerAPI('auth', {
  commonAuth: true,
  config: {
    baseURL: config.app.url.apiAuth,
  },
});
