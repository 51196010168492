import { Ref } from 'vue';
import { useElementEventDebounce } from '@/hooks/useElementEventDebounce';

export function useScrollDebounce(
  refElement: Ref<HTMLElement | Document | undefined>,
  onScroll: (event?: Event) => void,
  milliseconds = 50,
): void {
  useElementEventDebounce(refElement, 'scroll', onScroll, milliseconds);
}
