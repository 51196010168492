import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request = _resolveComponent("request")!

  return (_openBlock(), _createBlock(_component_request, {
    glossaries: _ctx.glossaries,
    transportation: _ctx.transportation,
    driverCurrentPosition: _ctx.driverCurrentPosition,
    tab: _ctx.tab || _ctx.TransportationModalRouteTabs.monitoring,
    type: _ctx.type,
    loadTransportation: _ctx.loadTransportation,
    transportationFirstLoading: _ctx.transportationFirstLoading
  }, null, 8, ["glossaries", "transportation", "driverCurrentPosition", "tab", "type", "loadTransportation", "transportationFirstLoading"]))
}