
import { defineComponent, inject } from 'vue';

export default defineComponent({
  name: 'FTableTd',
  props: {
    align: {
      type: String,
      default: 'left',
    },
  },
  setup() {
    const sticky = inject('sticky');
    const isTableHead = inject('isTableHead');
    const routeTo = inject('routeTo');

    return {
      sticky,
      isTableHead,
      routeTo,
    };
  },
});
