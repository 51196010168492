
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserProfileFragment } from '@/api/generated/portal_api';

export default defineComponent({
  name: 'RequestsHeader',
  props: {
    profile: {
      type: Object as PropType<UserProfileFragment>,
    },
  },
  setup() {
    const { t } = useI18n({
      messages: {
        ru: {},
      },
    });

    return {
      t,
    };
  },
});
