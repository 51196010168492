import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(["td", {
      sticky: _ctx.sticky && _ctx.isTableHead,
      head: _ctx.isTableHead,
      'with-link': _ctx.routeTo,
      [`align_${_ctx.align}`]: !!_ctx.align,
    }])
  }, [
    (_ctx.routeTo)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.routeTo,
          class: "route-link"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["to"]))
      : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
  ], 2))
}