import { createApp } from 'vue';
import { i18n } from '@/i18n';
import Wrapper from '@/components/Wrapper.vue';
import router from './router';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import { createPinia } from 'pinia';
import FTable from '@/components/UI/FTable/FTable.vue';
import FTableThead from '@/components/UI/FTable/FTableThead.vue';
import FTableTbody from '@/components/UI/FTable/FTableTbody.vue';
import FTableTr from '@/components/UI/FTable/FTableTr.vue';
import FTableTd from '@/components/UI/FTable/FTableTd.vue';
import FButton from '@/components/UI/FButton/FButton.vue';
import FInput from '@/components/UI/FInput/FInput.vue';
import FSelect from '@/components/UI/FSelect/FSelect.vue';
import FSpinner from '@/components/UI/FSpinner/FSpinner.vue';
import FTooltip from '@/components/UI/FTooltip/FTooltip.vue';
import FCheckbox from '@/components/UI/FCheckbox/FCheckbox.vue';
import App from './App.vue'; // Don't move!
import 'vue-toastification/dist/index.css';
import VueTheMask from 'vue-the-mask';
import { clickOutside } from '@/directives/clickOutside';
import { onlyPhoneSymbols } from '@/directives/onlyPhoneSymbols';

import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

const toastOptions: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  hideProgressBar: true,
};

const app = createApp(App)
  .use(i18n)
  .use(createPinia())
  .use(router)
  .use(Toast, toastOptions)
  .use(VueTheMask)
  .component('FTable', FTable)
  .component('FTableThead', FTableThead)
  .component('FTableTbody', FTableTbody)
  .component('FTableTr', FTableTr)
  .component('FTableTd', FTableTd)
  .component('FButton', FButton)
  .component('FInput', FInput)
  .component('FSpinner', FSpinner)
  .component('FSelect', FSelect)
  .component('FCheckbox', FCheckbox)
  .component('FTooltip', FTooltip)
  .component('Wrapper', Wrapper)
  .directive('click-outside', clickOutside)
  .directive('only-phone-symbols', onlyPhoneSymbols);

app.mount('#app');
