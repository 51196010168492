import { computed } from 'vue';
import { useProfileStore } from '@/stores/profile';
import { Role } from '@/api/generated/portal_api';

export const useUserRoles = () => {
  const profileStore = useProfileStore();
  const profile = computed(() => profileStore.profile);
  const roles = computed(() => profile.value?.roles);
  const isAdmin = computed(() => roles.value?.includes(Role.PortalSuperAdmin));
  const isFuraExpeditor = computed(() => roles.value?.includes(Role.PortalFuraExpeditor));
  const isClientExpeditor = computed(() => roles.value?.includes(Role.PortalClientExpeditor));
  const isCarrierExpeditor = computed(() => roles.value?.includes(Role.PortalCarrierExpeditor));
  const isAdminOrFuraSideExpeditor = computed(() => isAdmin.value || isFuraExpeditor.value);

  return {
    roles,
    isAdmin,
    isFuraExpeditor,
    isClientExpeditor,
    isCarrierExpeditor,
    isAdminOrFuraSideExpeditor,
  };
};
