import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3906cb48"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading"
}
const _hoisted_2 = {
  key: 1,
  class: "f-button-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_spinner = _resolveComponent("f-spinner")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), _mergeProps({
    type: "button",
    class: ["f-button-content", {
      [`f-button-content__${_ctx.color}`]: !!_ctx.color,
      [`f-button-content__${_ctx.size}`]: !!_ctx.size,
      ['f-button-content__without-label']: !_ctx.label,
      ['f-button-content__disabled']: _ctx.disabled || _ctx.loading,
      ['f-button-content__uppercase']: _ctx.uppercase,
    }],
    disabled: _ctx.disabled || _ctx.loading
  }, { ..._ctx.$props, ..._ctx.$attrs }), {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_f_spinner, { size: "24" })
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasIconSlot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(["f-button-label", { uppercase: _ctx.uppercase }]),
            style: _normalizeStyle({
        opacity: _ctx.loading ? 0 : 1,
      })
          }, _toDisplayString(_ctx.label), 7))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["class", "disabled"]))
}