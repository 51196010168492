
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FSpinner',
  props: {
    size: {
      type: [Number, String],
      default: 25,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
});
