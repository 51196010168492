import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0a2ec54"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "companyName"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wrapper = _resolveComponent("wrapper")!

  return (_openBlock(), _createBlock(_component_wrapper, { class: "header" }, {
    default: _withCtx(() => [
      (_ctx.profile?.company?.title)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.profile?.company?.title), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}