export function isActionKey(key: string) {
  return key === 'Meta' || key === 'Control';
}

export function isActionKeyCombination(key: string, actionKeyDown: string) {
  const isMetaOrControlDown = isActionKey(actionKeyDown);

  return (
    (isMetaOrControlDown && (key === 'a' || key === 'ф')) ||
    (isMetaOrControlDown && (key === 'z' || key === 'я')) ||
    (isMetaOrControlDown && (key === 'v' || key === 'м')) ||
    (isMetaOrControlDown && (key === 'c' || key === 'с')) ||
    (isMetaOrControlDown && (key === 'x' || key === 'ч')) ||
    (isMetaOrControlDown && (key === 'r' || key === 'к')) ||
    ['Delete', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].indexOf(key) !== -1
  );
}

export function checkTextIsSelected(input: HTMLInputElement) {
  return input.selectionStart === 0 && input.selectionEnd === input.value.length;
}
