import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_button_content = _resolveComponent("f-button-content")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.to)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.to,
        class: "f-button-link"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_f_button_content, _normalizeProps(_guardReactiveProps({ ..._ctx.$props, ..._ctx.$attrs })), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 16)
        ]),
        _: 3
      }, 8, ["to"]))
    : (_openBlock(), _createBlock(_component_f_button_content, _normalizeProps(_mergeProps({ key: 1 }, { ..._ctx.$props, ..._ctx.$attrs })), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 16))
}