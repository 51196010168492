
import { defineComponent, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDocumentKeyDown } from '@/hooks/useDocumentKeyDown';

export default defineComponent({
  name: 'Modal',
  props: {
    modalClass: String,
    size: {
      type: String,
      default: '',
    },
    goToPrevRouteOnClose: Boolean,
    visible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    useDocumentKeyDown('Escape', closeModal);

    const route = useRoute();
    const router = useRouter();
    const overlayDOM = ref<HTMLElement>();

    function closeModal() {
      if (!props.visible) {
        return;
      }

      emit('close');

      if (props.goToPrevRouteOnClose) {
        const prevRoute =
          route.matched.length >= 2 ? route.matched[route.matched.length - 2] : null;
        if (prevRoute) {
          router.push(prevRoute);
        }
      }
    }

    function closeModalByClickOutside(event: MouseEvent) {
      if (event.target === overlayDOM.value) {
        closeModal();
      }
    }

    const getAppDOM = () => {
      return document.getElementById('app');
    };

    watch(
      () => props.visible,
      value => {
        const appDOM = getAppDOM();
        if (!appDOM) return;

        if (value) {
          document.body.classList.add('overflow-y-hidden');
          // appDOM.classList.add('overflow-y-hidden');
        } else {
          document.body.classList.remove('overflow-y-hidden');
          // appDOM.classList.remove('overflow-y-hidden');
        }
      },
      {
        immediate: true,
      },
    );

    onUnmounted(() => {
      const appDOM = getAppDOM();
      if (!appDOM) return;
      appDOM.classList.remove('overflow-y-hidden');
    });

    return {
      closeModalByClickOutside,
      overlayDOM,
    };
  },
});
