
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ShortsTransportationsWithCount } from '@/stores/transportations';
import { ShortTransportationFragment } from '@/api/generated/portal_api';
import TableTbodyWithPaggination from '@/components/TableTbodyWithPaggination.vue';
import { formatDateToHumanText, formatDateToTimeText, formatDateWithTime } from '@/utils/date';
import { delayToHumanText } from '@/utils/delayToHumanText';
import IconArrowAltRight from '@/components/UI/Icons/IconArrowAltRight.vue';
import IconTimeMark from '@/components/UI/Icons/IconTimeMark.vue';

import {
  ClientDriverStatus,
  TraceStatus,
  UserProfileFragment,
  DriverActivityStatus,
} from '@/api/generated/portal_api';
import { TransportationsRouteTypes } from '@/router/constants';
import { useUserRoles } from '@/hooks/useUserRoles';

export default defineComponent({
  name: 'RequestsTable',
  components: {
    IconTimeMark,
    TableTbodyWithPaggination,
    IconArrowAltRight,
  },
  props: {
    transportationsLoading: Boolean,
    transportations: {
      type: Object as PropType<ShortsTransportationsWithCount>,
    },
    loadTransportations: Function,
    type: {
      type: String as PropType<TransportationsRouteTypes>,
      required: true,
    },
    profile: {
      type: Object as PropType<UserProfileFragment>,
    },
  },
  setup() {
    const { isAdminOrFuraSideExpeditor } = useUserRoles();
    const getLastUpdatedTime = (updatedAt: string) =>
      `Последнее обновление: ${formatDateWithTime(updatedAt)}`;

    const driverIsOnline = (status: DriverActivityStatus) =>
      status === DriverActivityStatus.DriverActivityStatusRides ||
      status === DriverActivityStatus.DriverActivityStatusStandsStill;

    const beingLateForVisible = (transportation: ShortTransportationFragment) => {
      return (
        (transportation.statusDetails.traceStatus === TraceStatus.TraceStatusTargetsInProgress &&
          transportation.currentTarget?.driverIsLate) ||
        (transportation.statusDetails.traceStatus === TraceStatus.TraceStatusCompleted &&
          transportation.currentTarget?.driverWasLate &&
          transportation.currentTarget?.delay > 0)
      );
    };

    const getIconTimeType = (transportation: ShortTransportationFragment) => {
      const typeByClientStatus = {
        TRACE_STATUS_UNDEFINED: 'upcoming',
        TRACE_STATUS_UPCOMING: 'upcoming',
        TRACE_STATUS_NO_INIT_DATA: 'noInitData',
      };

      let status;
      if (transportation.statusDetails.traceStatus === 'TRACE_STATUS_TARGETS_IN_PROGRESS') {
        status = transportation.currentTarget?.driverIsLate ? 'late' : 'inTime';
      } else if (transportation.statusDetails.traceStatus === 'TRACE_STATUS_COMPLETED') {
        status = transportation.tardinessRecorded ? 'late' : 'completed';
      } else {
        status = typeByClientStatus[transportation.statusDetails.traceStatus];
      }

      return status;
    };

    const { t } = useI18n({
      messages: {
        ru: {
          request: 'Заявка',
          driverStatus: 'Статус водителя',
          dateTime: 'Ожидаемое время',
          consignee: 'Грузополучатель',
          client: 'Клиент',
          route: 'Маршрут',
          beingLateFor: 'Опоздание на',
          transportationsActiveEmpty: 'У вас пока нет активных заявок',
          transportationsCompletedEmpty: 'У вас пока нет завершенных заявок',
        },
      },
    });

    return {
      t,
      getIconTimeType,
      formatDateToHumanText,
      formatDateToTimeText,
      ClientDriverStatus,
      TransportationsRouteTypes,
      delayToHumanText,
      TraceStatus,
      driverIsOnline,
      getLastUpdatedTime,
      beingLateForVisible,
      isAdminOrFuraSideExpeditor,
    };
  },
});
