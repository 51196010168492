
import { defineComponent, ref } from 'vue';
import { useScrollDebounce } from '@/hooks/useScrollDebounce';

export default defineComponent({
  name: 'TableTbodyWithPaggination',
  props: {
    loading: {
      type: Boolean,
    },
    hasNextPage: {
      type: Boolean,
    },
    cursor: String,
    scrollOnContainer: Boolean,
    getItems: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const tbodyDOM = ref<HTMLElement>();
    const scrollPosition = ref(0);
    let nextItemsLoading = false;

    async function onScroll() {
      if (!tbodyDOM.value) {
        return;
      }

      const { scrollTop, clientHeight, scrollHeight } = props.scrollOnContainer
        ? tbodyDOM.value
        : document.documentElement;

      if (
        !nextItemsLoading &&
        props.hasNextPage &&
        scrollTop > scrollPosition.value &&
        scrollTop + clientHeight > scrollHeight / 1.7
      ) {
        nextItemsLoading = true;
        await props.getItems({
          after: props.cursor,
        });
        nextItemsLoading = false;
      }

      scrollPosition.value = scrollTop;
    }

    useScrollDebounce(props.scrollOnContainer ? tbodyDOM : ref(document), onScroll);

    return {
      tbodyDOM,
    };
  },
});
