import { ref } from 'vue';

export function useFocus(emit: (event: 'focus' | 'blur') => void): Record<string, unknown> {
  const focused = ref(false);

  function handleFocus() {
    focused.value = true;
    emit('focus');
  }

  function handleBlur() {
    focused.value = false;
    emit('blur');
  }

  return { focused, handleFocus, handleBlur };
}
