import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_spinner = _resolveComponent("f-spinner")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.glossariesLoading)
    ? (_openBlock(), _createBlock(_component_f_spinner, {
        key: 0,
        size: "24",
        class: "loading"
      }))
    : (_ctx.mounted && (_ctx.freeAccess || !_ctx.glossariesLoading))
      ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
      : _createCommentVNode("", true)
}