
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { useFocus } from '@/hooks/useFocus';

export default defineComponent({
  name: 'FInput',
  emits: ['change', 'update:modelValue', 'focus', 'blur'],
  props: {
    modelValue: [String, Number] as PropType<string | number>,
    type: {
      type: String,
      default: 'text',
    },
    focusOnMount: Boolean,
    placeholder: String,
    disabled: Boolean,
    warning: Boolean,
    error: Boolean,
    floatLabel: {
      type: String,
    },
    labelUppercase: Boolean,
    maxlength: String,
    max: Number,
    mask: String,
  },
  setup(props, { emit }) {
    const { focused, handleFocus, handleBlur } = useFocus(emit);
    const inputDOM = ref<HTMLInputElement | null>(null);

    onMounted(() => {
      if (props.focusOnMount && inputDOM.value) {
        inputDOM.value.focus();
      }
    });

    function input(event: KeyboardEvent) {
      let value: string | number = (event.target as HTMLInputElement).value;
      if (props.type === 'number') {
        value = Number(value);
      }
      emit('update:modelValue', value);
      emit('change', value);
    }

    return {
      input,
      focused,
      handleFocus,
      handleBlur,
      inputDOM,
    };
  },
});
