import { intervalToDuration } from 'date-fns';

function pluralize(count: number, words: string[]) {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    count + ' ' + words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]
  );
}

export function delayToHumanText(time: number): string {
  if (!time) {
    return '';
  }

  const { months, days, hours, minutes } = intervalToDuration({ start: 0, end: time });

  let text = '';

  if (months) {
    text += pluralize(months, ['месяц', 'месяца', 'месяцев']) + ' ';
  }

  if (days) {
    text += pluralize(days, ['день', 'дня', 'дней']) + ' ';
  }
  if (hours) {
    text += pluralize(hours, ['час', 'часа', 'часов']) + ' ';
  }

  if (minutes) {
    text += pluralize(minutes, ['минуту', 'минуты', 'минут']) + ' ';
  }

  return text.trim();
}
