
import { computed, defineComponent, PropType } from 'vue';
import Requests from './Requests.vue';
import { useTransportationsStore } from '@/stores/transportations';
import { useAlmanacStore } from '@/stores/almanac';
import { TransportationsRouteTypes } from '@/router/constants';
import { useProfileStore } from '@/stores/profile';

export default defineComponent({
  name: 'RequestsContainer',
  inheritAttrs: false,
  props: {
    type: {
      type: String as PropType<TransportationsRouteTypes>,
      required: true,
    },
  },
  components: {
    Requests,
  },
  setup(props) {
    const transportationsStore = useTransportationsStore();
    const almanacStore = useAlmanacStore();
    const profileStore = useProfileStore();
    const profile = computed(() => profileStore.profile);
    const transportationsLoading = computed(() => transportationsStore.transportationsLoading);
    const transportations = computed(() => transportationsStore.transportations);
    const glossaries = computed(() => almanacStore.glossaries);
    const transportationsCountLoading = computed(
      () => transportationsStore.transportationsCountLoading,
    );
    const transportationsCountByType = computed(
      () => transportationsStore.transportationsCountByType,
    );

    const silentReloadTransportations = () => transportationsStore.silentReloadTransportations();
    const silentReloadTransportationsCount = () =>
      transportationsStore.silentReloadTransportationsCount();

    const loadTransportations = ({
      after,
    }: {
      after?: string;
    } = {}) => {
      const filters = {
        isCompleted: props.type !== TransportationsRouteTypes.active,
      };
      return transportationsStore.loadTransportations({
        filters,
        after,
      });
    };

    const loadTransportationsCount = () => {
      return transportationsStore.loadTransportationsCount();
    };

    return {
      transportationsLoading,
      transportations,
      loadTransportations,
      loadTransportationsCount,
      silentReloadTransportationsCount,
      silentReloadTransportations,
      glossaries,
      transportationsCountByType,
      transportationsCountLoading,
      profile,
    };
  },
});
