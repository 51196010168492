
import { watch, ref, computed, onMounted, onUnmounted, PropType } from 'vue';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import FSpinner from '@/components/UI/FSpinner/FSpinner.vue';
import FIconArrowDownFlat from '@/components/UI/Icons/FIconArrowDownFlat.vue';
import FIconClose from '@/components/UI/Icons/FIconClose.vue';
import FIconCircleClose from '@/components/UI/Icons/FIconCircleClose.vue';
import { defineComponent } from 'vue';

type OptionValue = string | number | Record<string, unknown>;
type Option = {
  label: string;
  value: OptionValue;
  labelForFilter?: string;
  selectedLabel?: string;
  disabled?: boolean;
  className?: string;
};
type Options = Option[];
type SavedOptions = {
  items?: Options;
  totalCount?: number;
};

export default defineComponent({
  name: 'FSelect',
  components: {
    FIconArrowDownFlat,
    FIconClose,
    FIconCircleClose,
    FSpinner,
  },
  emits: ['show-popover', 'hide-popover', 'update:modelValue', 'input'],
  props: {
    options: {
      // Каждый options может содержать в себе
      // label (обязательно) - html контент option'а
      // value (обязательно) - значение option'а
      // labelForFilter - label, который используется для поиска внутри фильтра.
      // Если не передать, то генерируется автоматически из label (удаляются html теги и lowerCase)
      // selectedLabel - label, который показывается у выбранного option'а.
      // Если не передать, то юзается label.
      // disabled (необязательно) - нельзя выбрать
      // className - дополнительный класс на option-label
      type: Array as PropType<Options>,
      default: () => [],
    },
    overflow: Boolean,
    disabled: Boolean,
    floatLabel: String,
    placeholder: String,
    placeholderHidden: Boolean,
    // Кастомный placeholder для фильтра
    filterPlaceholder: String,
    loadOptionsOnFilterValueMinLength: {
      type: Number,
      default: 3,
    },
    // Функция, которая вызывается при фильтре.
    // Нужно для подгрузки элементов при вводе.
    loadOptionsOnFilter: Function,
    loadOptionsOnFilterDebounce: {
      type: Number,
      default: 700,
    },
    filter: {
      // true или кастомная функция фильтра
      // Внимание. Значение true показывает поле поиска и ищет на фронте по тексту.
      // Или мы можем указать функцию и опять же искать на фронте по тексту.
      // Чтобы искать с запросом на бэк юзается в связке с "loadOptionsOnFilter"
      // :filter="true"
      // :loadOptionsOnFilter="getItems"
      type: [Boolean, Function],
      default: false,
    },
    filterDebounce: {
      // Debounce для инпута фильтра
      // Через столько мс поменяется значение у filterValueDebounced
      type: Number,
      default: 0,
    },
    error: Boolean,
    errorLabel: String,
    warning: Boolean,
    warningLabel: String,
    modelValue: [String, Number, Boolean, Object, Array] as PropType<OptionValue>,
    popoverClass: String,
    totalCount: Number,
    id: String,
    clearButtonHidden: Boolean,
    filteredItemsVisibleLimit: {
      // Лимит показанных элементов внутри поповера при включенном фильтре
      // Если надо отключить, то можно передать false
      // Внимание Если есть loadOptionsOnFilter, то сервер и так нам может выдать с лимитом,
      // поэтому в таком случае можно поставить false или установить одинаковое значение с бэком
      type: [Number, Boolean] as PropType<number | false>,
      default: 100,
    },
    // Если true, то делаем emit('update:modelValue') только при закрытие поповера, если это мультиселект
    emitUpdateValueOnClose: Boolean,
    // Если true, то делаем emit('input') только при закрытие поповера, если это мультиселект
    emitInputOnClose: Boolean,
    // Скрыть надпись "Еще N элементов", если вышло за лимит
    limitLabelHidden: Boolean,
    // Кастомная надпись "Еще N элементов"
    limitLabel: String,
    // Кастомная надпись "Не найдено"
    notFoundLabel: String,
    // Отключить textOverflow у выбранного option'а
    valueTextOverflowDisabled: Boolean,
    loading: Boolean,
    multiple: Boolean,
    labelUppercase: Boolean,
    chips: Boolean,
    // Используется в связке с loadOptionsOnFilter
    // Востанавливает самые первые значения options, чтобы при сбросе фильтра не делать запрос
    // Зачем это нужно? Кликаем по селекту, тот показывает первые 100.
    // Далее делаем поиск и показывает уже новые.
    // Далее открываем селект заново и с этим prop будут показаны первые элементы, что логичнее,
    // т.к. поле поиска сбрасывается.
    // Без него всегда будет показывать результаты последнего поиска.
    // Или же мы можем сами сбрасывать вручную на событие hide-popover
    // Например @hide-popover="loadItemsBySearch('') пустая строка
    // Другим решением было бы сделать вызов функции поиска на закрытие попапа,
    // но был бы лишний запрос на бэк каждый раз.
    // Еще одним решением было бы хранить items в стейте и тогда options prop
    // всегда бы хранил дефолтные options, а в стейте были бы отфильтрованные.
    // Но это порождает ряд проблем, из-за которых сложно разобраться в коде.
    // Например (пришлось бы передавать функцию без диспатча, а прямо на api),
    // чтобы не перетирать в сторе дефолтные options
    restoreOptionsOnHidePopover: {
      type: Boolean,
      default: true,
    },
    emptyLabelIfEmptyFilterHidden: Boolean,
    firstValueByDefault: Boolean,
    multipleValueCommaDivider: Boolean,
  },
  setup(props, { emit }) {
    const optionsVisible = ref(false);
    const filterValue = ref('');
    const filterValueDebounced = ref('');
    const selectedOption = ref<Option | null>(null);
    const selectedOptions = ref<OptionValue[]>([]);
    const prevPageYOffset = ref<number | null>(null);
    const localOptions = ref<Options>([]);
    const localValue = computed(() =>
      props.multiple ? selectedOptions.value : selectedOption.value,
    );
    const localOptionsTotalCount = ref(0);
    // загрузка опций, если есть loadOptionsOnFilter.
    const optionsLoading = ref(false);
    // срабатывает при изменении фильтра,
    // если есть если есть loadOptionsOnFilter. Спустя debounce запустим загрузку опций.
    // Это нужно, чтобы сразу не рисовать "ничего не найдено", но и лоадер тоже отрисовать позже.
    const loadOptionsWaiting = ref(false);
    const filterInputDOM = ref<HTMLElement | null>(null);
    const selectOptionsPopoverDOM = ref<HTMLElement | null>(null);
    const selectOptionsDOM = ref<HTMLElement | null>();
    const savedOptions = ref<SavedOptions>({});

    function setPopoverPosition() {
      if (!selectOptionsDOM.value) {
        return;
      }

      const selectWidth = selectOptionsDOM.value.offsetWidth;
      const { left, top } = selectOptionsDOM.value.getBoundingClientRect();

      if (selectOptionsPopoverDOM.value) {
        selectOptionsPopoverDOM.value.style.width = `${selectWidth}px`;
        selectOptionsPopoverDOM.value.style.left = `${left}px`;
        selectOptionsPopoverDOM.value.style.top = `${
          top + selectOptionsDOM.value.offsetHeight - 7
        }px`;
      }
    }

    const setPopoverPositionDebounce = debounce(setPopoverPosition, 50);

    function setLocalOptions(options: Options = [], totalCount?: number) {
      // Если totalCount передан, то устанавливаем его
      // Если нет, то берем из длины options
      localOptions.value = options.map(option => ({
        ...option,
        labelForFilter:
          option.labelForFilter || (option.label || '').replace(/<[^>]+>/g, '').toLowerCase(),
      }));
      localOptionsTotalCount.value = totalCount || props.totalCount || options.length;
      if (
        props.firstValueByDefault &&
        !localValue.value &&
        localOptions.value &&
        localOptions.value[0]
      ) {
        selectOption(localOptions.value[0]);
      }
    }

    async function handleLoadItemsOnFilter(text: string) {
      if (!props.loadOptionsOnFilter) {
        return;
      }

      optionsLoading.value = true;
      loadOptionsWaiting.value = false;
      await props.loadOptionsOnFilter(text);
      optionsLoading.value = false;
    }

    const handleLoadItemsOnFilterDebounce = debounce(
      handleLoadItemsOnFilter,
      props.loadOptionsOnFilterDebounce,
    );

    function inputFilter(event: KeyboardEvent) {
      if (props.loadOptionsOnFilter) {
        loadOptionsWaiting.value = true;
        const target = event.target as HTMLInputElement;
        handleLoadItemsOnFilterDebounce(target.value);
      }
    }

    function showPopover() {
      filterValue.value = '';
      filterValueDebounced.value = '';

      setTimeout(() => {
        setPopoverPosition();
        if (props.filter && filterInputDOM.value) {
          filterInputDOM.value.focus();
        }
      }, 0);
      emit('show-popover');

      if (
        props.restoreOptionsOnHidePopover &&
        props.loadOptionsOnFilter &&
        props.options.length &&
        !savedOptions.value.items
      ) {
        savedOptions.value = {
          items: [...props.options],
          totalCount: props.totalCount || props.options.length,
        };
      }
      optionsVisible.value = true;
    }

    function onDocumentScroll() {
      const { pageYOffset } = window;

      // Не используем debounce, но проверяем изменение pageYOffset.
      // Это позволяет переместить поповер мгновенно без дергания и оптимизировать при скролле.
      if (prevPageYOffset.value !== pageYOffset && selectOptionsPopoverDOM.value) {
        setPopoverPosition();
        prevPageYOffset.value = pageYOffset;
      }
    }

    function hidePopover() {
      optionsVisible.value = false;
      if (props.multiple) {
        if (props.emitUpdateValueOnClose) {
          emit('update:modelValue', localValue.value);
        }
        if (props.emitInputOnClose) {
          emit('input', localValue.value);
        }
      }
      emit('hide-popover');
      if (
        props.restoreOptionsOnHidePopover &&
        props.loadOptionsOnFilter &&
        savedOptions.value.items &&
        savedOptions.value.items.length
      ) {
        setLocalOptions(savedOptions.value.items, savedOptions.value.totalCount);
      }
    }

    function selectOption(option: Option) {
      if (props.multiple) {
        const foundIndex = selectedOptions.value.findIndex(value => isEqual(value, option.value));

        if (foundIndex !== -1) {
          // удаляем
          selectedOptions.value = selectedOptions.value.filter(
            (item, index) => index !== foundIndex,
          );
        } else {
          // Добавляем
          selectedOptions.value.push(option.value);
        }

        if (!props.emitUpdateValueOnClose) {
          emit('update:modelValue', selectedOptions.value);
        }
        if (!props.emitInputOnClose) {
          emit('input', selectedOptions.value);
        }
      } else {
        const value = cloneDeep(option.value);
        emit('update:modelValue', value);
        emit('input', value);
        hidePopover();
      }

      if (!props.multiple) {
        selectedOption.value = { ...option };
      }
    }

    function clearValue() {
      emit('update:modelValue', null);
      emit('input', null);
      hidePopover();
      selectedOption.value = null;
    }

    function clearSavedOptions() {
      savedOptions.value = {};
    }

    function removeMultipleValueItem(index: number) {
      if (!Array.isArray(localValue.value)) {
        return;
      }
      const valueFiltererd = localValue.value.filter(
        (item: OptionValue, idx: number) => idx !== index,
      );
      emit('update:modelValue', valueFiltererd);
      emit('input', valueFiltererd);
    }

    function checkOptionIsActive(option: Option) {
      return props.multiple && Array.isArray(localValue.value)
        ? localValue.value.some((item: OptionValue) => isEqual(option.value, item))
        : isEqual(option.value, localValue.value);
    }

    onMounted(() => {
      document.addEventListener('scroll', onDocumentScroll);
      window.addEventListener('resize', setPopoverPositionDebounce);

      const popoverDOM = document.getElementById('popover');
      if (!popoverDOM) {
        const newPopoverDOM = document.createElement('DIV');
        newPopoverDOM.setAttribute('id', 'popover');
        document.body.appendChild(newPopoverDOM);
      }
    });

    onUnmounted(() => {
      document.removeEventListener('scroll', onDocumentScroll);
      window.removeEventListener('resize', setPopoverPositionDebounce);
    });

    setLocalOptions(props.options);

    watch(
      () => props.options,
      value => {
        setLocalOptions(value);
      },
      { deep: true },
    );

    const canOptionsShow = computed(
      () => !props.disabled && !props.loading && (localOptions.value || []).length,
    );

    const valueLabel = computed(() => {
      const option = !isEmpty(selectedOption.value)
        ? selectedOption.value
        : localOptions.value.find(item => isEqual(localValue.value, item.value));
      return option ? option.selectedLabel || option.label : '';
    });

    const multipleValueLabels = computed(() => {
      if (!props.multiple) {
        return [];
      }
      const result: OptionValue[] = [];

      if (Array.isArray(localValue.value)) {
        localValue.value.forEach((item: OptionValue) => {
          const foundInOption = localOptions.value.find(option => isEqual(option.value, item));
          if (foundInOption) {
            result.push(foundInOption.selectedLabel || foundInOption.label);
          }
        });
      }

      return result;
    });

    const hasValue = computed(() =>
      props.multiple
        ? multipleValueLabels.value && multipleValueLabels.value.length > 0
        : !!valueLabel.value,
    );

    const placeholderLabel = computed(() => {
      let placeholder = props.placeholder || props.floatLabel || 'Выберите из списка';

      if ((localValue.value || optionsVisible.value) && props.floatLabel) {
        placeholder = props.floatLabel;
      }

      return placeholder;
    });

    const optionsFiltered = computed(() => {
      // Фильтр поиска по options
      // По умолчанию работает по labelForFilter внутри option.
      // Если у option отсутствует labelForFilter, то он создается из label.toLowerCase().
      // Но можно задать вручную. Это может потребоваться, чтобы разделить разметку и логику поиска
      // Умеет также вызывать кастомную функцию.

      const filterValueLowerCase = (filterValueDebounced.value || '').toLowerCase();

      return localOptions.value.filter((option: Option) => {
        return typeof props.filter === 'function'
          ? props.filter(filterValueLowerCase, option)
          : (option.labelForFilter || '').indexOf(filterValueLowerCase) !== -1;
      });
    });

    const selectOptions = computed(() => {
      // Если указан filter (true или функуция), то берем из optionsFiltered
      // Иначе из localOptions.
      // Если есть filter и loadOptionsOnFilter, то не фильтруем сами,
      // т.к. подразумевается, что бэк уже все ок вернул.
      let options =
        props.filter && !props.loadOptionsOnFilter ? optionsFiltered.value : localOptions.value;

      if (props.filteredItemsVisibleLimit !== false) {
        options = options.slice(0, props.filteredItemsVisibleLimit);
      }

      if (
        !props.multiple &&
        props.filter &&
        !filterValue.value &&
        selectedOption.value &&
        selectedOption.value.value
      ) {
        // Выбранный переносим наверх, если есть фильтр и в отфильтрованный список не попал
        // Не включать в режиме multiple

        options = options.filter(
          ({ value }) => !isEqual(value, (selectedOption.value || {}).value),
        );
        options.unshift(selectedOption.value);
      }

      return options;
    });

    function onChangeFilterValue() {
      filterValueDebounced.value = filterValue.value;
    }

    function setSelectedOptionFromProp() {
      if (props.multiple) {
        if (Array.isArray(props.modelValue)) {
          selectedOptions.value = [...props.modelValue];
        }
      } else {
        const foundInOptions = localOptions.value.find(({ value }) =>
          isEqual(value, props.modelValue),
        );
        if (foundInOptions || !props.loadOptionsOnFilter) {
          // Режим без подгрузки с бэка или элемент найден
          selectedOption.value = foundInOptions ? { ...foundInOptions } : null;
        }
      }
    }

    const onChangeFilterValueDebounce = debounce(onChangeFilterValue, props.filterDebounce);

    watch(
      () => filterValue.value,
      () => {
        onChangeFilterValueDebounce();
      },
    );

    watch(
      () => props.modelValue,
      () => {
        setSelectedOptionFromProp();
      },
      { immediate: true },
    );

    return {
      checkOptionIsActive,
      removeMultipleValueItem,
      clearValue,
      selectOption,
      hidePopover,
      setPopoverPosition,
      showPopover,
      inputFilter,
      optionsVisible,
      filterValue,
      filterValueDebounced,
      selectedOption,
      selectedOptions,
      prevPageYOffset,
      localOptions,
      localOptionsTotalCount,
      optionsLoading,
      loadOptionsWaiting,
      canOptionsShow,
      hasValue,
      localValue,
      placeholderLabel,
      selectOptions,
      optionsFiltered,
      filterInputDOM,
      multipleValueLabels,
      valueLabel,
      selectOptionsDOM,
      selectOptionsPopoverDOM,
      clearSavedOptions,
    };
  },
});
