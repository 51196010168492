
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'FButtonContent',
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    uppercase: Boolean,
  },
  setup(props, { slots }) {
    // Default view as button
    const componentName = ref('button');
    const hasIconSlot = computed(() => !!slots.icon);

    if (props.simple) {
      // alternative view as div
      componentName.value = 'span';
    }
    return {
      componentName,
      hasIconSlot,
    };
  },
});
