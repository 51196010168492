import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-964d97fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "f-ui-popup-container",
  ref: "popupParentContainerDOM"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_f_ui_click_outside = _resolveDirective("f-ui-click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
      _createVNode(_Transition, { name: "f-ui-fade" }, {
        default: _withCtx(() => [
          (_ctx.visible)
            ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["f-ui-popup-content", [
            _ctx.popupClass,
            {
              fixed: _ctx.fixed,
              [_ctx.color]: true,
              [`padding_${_ctx.padding}`]: true,
              border_radius: _ctx.borderRadius,
            },
          ]]),
                ref: "popupContentDOM",
                style: _normalizeStyle({
            left: `${_ctx.popupLeft}px`,
            top: `${_ctx.popupTop}px`,
          })
              }, [
                _renderSlot(_ctx.$slots, "default", { close: _ctx.closePopup }, undefined, true)
              ], 6)), [
                [_directive_f_ui_click_outside, {
            handler: _ctx.closePopup,
          }]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]))
  ], 512))
}