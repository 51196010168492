import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '@/views/Login.vue';
import RequestsContainer from '@/views/RequestsContainer.vue';
import RequestContainer from '@/views/RequestContainer.vue';
import Logout from '@/views/Logout.vue';
import { loggedInGuard } from './guards';
import { useAuthStore } from '@/stores/auth';
import config from '@/../config/config';
import { TransportationsRouteTypes } from './constants';
// import { useAuthStore } from '@/stores/auth';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      freeAccess: true,
    },
  },
  {
    path: '/trace/:type',
    name: 'transportations',
    component: RequestsContainer,
    props: true,
    children: [
      {
        path: ':id/:tab?',
        name: 'transportation',
        component: RequestContainer,
        props: true,
      },
    ],
  },
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'transportations',
      params: {
        type: TransportationsRouteTypes.active,
      },
    },
  },
  {
    path: '/trace',
    name: 'transportations-default-redirect',
    redirect: {
      name: 'transportations',
      params: {
        type: TransportationsRouteTypes.active,
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      freeAccess: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = await loggedInGuard();
  const authStore = useAuthStore();

  if (!to.meta.freeAccess && !isLoggedIn) {
    authStore.setRedirectAfterLogin(to);
    next({ name: 'login' });
  } else {
    next();
  }

  if (config.app.services.yandex_metrica_id && window.ym) {
    window.ym(config.app.services.yandex_metrica_id, 'hit', `/#${to.path}`, {
      referer: `/#${from.path}`,
    });
  }
});

export default router;
