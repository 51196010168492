import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95f065b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "f-input-container" }
const _hoisted_2 = ["maxlength", "max", "type", "disabled", "placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.floatLabel)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["f-input-label", { active: _ctx.focused || _ctx.modelValue, uppercase: _ctx.labelUppercase }])
        }, _toDisplayString(_ctx.floatLabel), 3))
      : _createCommentVNode("", true),
    _createElementVNode("input", _mergeProps({
      class: ["f-input", {
        warning: _ctx.warning,
        error: _ctx.error,
        uppercase: _ctx.labelUppercase,
        active: _ctx.focused || _ctx.modelValue,
      }],
      maxlength: _ctx.maxlength,
      max: _ctx.max,
      type: _ctx.type,
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args)))
    }, _ctx.$attrs, {
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      ref: "inputDOM"
    }), null, 16, _hoisted_2)
  ]))
}