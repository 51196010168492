<template>
  <svg :width="width" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4161 5.2177L12.8097 5.82576L10.1742 3.19025L10.7823 2.58388C10.9533 2.40274 11.159 2.25775 11.3871 2.1575C11.6152 2.05724 11.8611 2.00375 12.1102 2.00019C12.3593 1.99663 12.6066 2.04308 12.8375 2.13677C13.0684 2.23047 13.2781 2.36953 13.4543 2.5457C13.6304 2.72188 13.7695 2.93161 13.8632 3.16247C13.9569 3.39333 14.0033 3.64064 13.9998 3.88977C13.9962 4.13889 13.9427 4.38478 13.8425 4.61287C13.7422 4.84096 13.5972 5.04662 13.4161 5.2177ZM2.85036 10.514L9.45608 3.90833L12.0848 6.554L5.4791 13.1597C5.41632 13.2229 5.33806 13.2684 5.25213 13.2918L2.63864 13.9829C2.59614 13.9944 2.55226 14.0001 2.50822 13.9998C2.43036 13.9998 2.35355 13.982 2.2837 13.9476C2.21385 13.9132 2.15284 13.8632 2.10537 13.8015C2.05791 13.7398 2.02525 13.668 2.00993 13.5917C1.99462 13.5154 1.99704 13.4365 2.01702 13.3613L2.71316 10.7512C2.73583 10.6609 2.78336 10.5787 2.85036 10.514Z"
      class="primary"
      :class="{ disabled }"
    />
    <!-- eslint-enable max-len -->
  </svg>
</template>

<script>
export default {
  name: 'IconEditPencil',
  props: {
    width: {
      type: Number,
      default: 16,
    },
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.primary {
  fill: var(--blue);
}

.disabled {
  fill: var(--grey);
}
</style>
