
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconArrowAltRight',
  props: {
    bgColor: {
      type: String,
      default: '#F1F1F1',
    },
    color: {
      type: String,
      default: '#9BA9B9',
    },
    width: {
      type: Number,
      default: 24,
    },
  },
});
