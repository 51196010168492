import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_row_label_value = _resolveComponent("row-label-value")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_row_label_value, {
      label: _ctx.t('name')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.transportation.request.responsibleExpeditor.fullName || _ctx.t('none')), 1)
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_row_label_value, {
      label: _ctx.t('phone')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.transportation.request.responsibleExpeditor.phoneNumber || _ctx.t('none')), 1)
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}