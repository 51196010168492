
import { ref, defineComponent, onMounted, watch, PropType } from 'vue';
import config from '@/../config/config';
import mapboxgl, { Marker, Map } from 'mapbox-gl';
import { drawLines, drawPlaces, centerMap, drawCurrentPlace } from '@/components/Map/utils';
import { TrackFragment, TransportationFragment } from '@/api/generated/portal_api';

export default defineComponent({
  name: 'Map',
  props: {
    transportation: {
      type: Object as PropType<TransportationFragment>,
      required: true,
    },
    driverCurrentPosition: {
      type: Object as PropType<TrackFragment['driverLocation']>,
      required: false,
    },
  },
  setup(props) {
    let map: Map;
    let drawnCurrentPlace: Marker | undefined;
    const mapVisible = ref(false);

    function clearRoutesLines() {
      if (!map) return;
      map.removeLayer('route');
      map.removeSource('route');
      // map.removeLayer('prev-route');
      // map.removeSource('prev-route');
    }

    function clearCurrentPosition() {
      if (!map) return;
      if (drawnCurrentPlace) {
        drawnCurrentPlace.remove();
        drawnCurrentPlace = undefined;
      }
    }

    function drawRoutesLines() {
      if (!map) return;

      if (props.transportation.track?.path) {
        drawLines(map, props.transportation.track?.path, '#0d5eff', 'route');
      }

      // drawLines(map, props.transportationRoutesLines.passedRoutePoints, '#7e7e7e', 'prev-route');
    }

    function drawCurrentPosition() {
      if (!map) return;

      if (props.driverCurrentPosition?.coordinates) {
        drawnCurrentPlace = drawCurrentPlace(map, props.driverCurrentPosition.coordinates);
      }
    }

    onMounted(async () => {
      mapboxgl.accessToken = config.app.services.mapBoxToken;
      map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/dev-services/ckpfzkdw01q1418o0scxuzhea',
      });
      if (map) {
        map.on('load', () => {
          if (map) {
            if (props.transportation.targets) {
              centerMap(
                map,
                props.transportation.targets,
                props.driverCurrentPosition?.coordinates,
                props.transportation.track?.path,
              );
            }
            drawPlaces(map, props.transportation.targets);
            drawRoutesLines();
            drawCurrentPosition();
            mapVisible.value = true;
          }
        });
      }
    });

    watch(
      () => props.driverCurrentPosition,
      () => {
        clearCurrentPosition();
        drawCurrentPosition();
        clearRoutesLines();
        drawRoutesLines();
      },
      { deep: true },
    );

    return {
      mapVisible,
    };
  },
});
