import { isActionKeyCombination, checkTextIsSelected, isActionKey } from './utils';
import { Directive } from 'vue';

export const onlyPhoneSymbols: Directive = {
  mounted(el) {
    el.handleKeyDown = (event: KeyboardEvent) => {
      if (!event.target) {
        return;
      }
      const target = event.target as HTMLInputElement;
      const textIsSelected = checkTextIsSelected(target);
      const prevSymbol = target.value.length ? target.value[target.value.length - 1] : null;

      const isNumberOrActionKey =
        isActionKeyCombination(event.key, el.actionKeyDown) ||
        (event.key === '+' && (target.value.length === 0 || target.selectionStart === 0)) ||
        (event.key === ' ' && (prevSymbol !== ' ' || textIsSelected)) ||
        (event.key === '-' && (prevSymbol !== '-' || textIsSelected)) ||
        (event.key === '(' && (prevSymbol !== '(' || textIsSelected)) ||
        (event.key === ')' && (prevSymbol !== ')' || textIsSelected)) ||
        (parseInt(event.key, 10) >= 0 && parseInt(event.key, 10) <= 9);
      if (!isNumberOrActionKey) {
        event.preventDefault();
      }
      if (isActionKey(event.key)) {
        el.actionKeyDown = event.key;
      }
    };
    el.handleKeyUp = (event: KeyboardEvent) => {
      if (isActionKey(event.key)) {
        el.actionKeyDown = null;
      }
    };

    el.addEventListener('keydown', el.handleKeyDown);
    el.addEventListener('keyup', el.handleKeyUp);
  },
  beforeUnmount(el) {
    el.removeEventListener('keydown', el.handleKeyDown);
    el.removeEventListener('keydown', el.handleKeyUp);
  },
};
