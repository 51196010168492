
import { defineComponent, onBeforeMount } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Logout',
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();

    onBeforeMount(() => {
      authStore.logout();
      router.push({
        name: 'login',
      });
    });
  },
});
