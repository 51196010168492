
import { defineComponent, computed, PropType } from 'vue';
import { TransportationsSummaryFragment } from '@/api/generated/portal_api';

export default defineComponent({
  name: 'IconTimeMark',
  props: {
    width: {
      type: Number,
      default: 33,
    },
    type: {
      type: String as PropType<keyof TransportationsSummaryFragment | 'completed'>,
      required: true,
    },
  },
  setup(props) {
    const color = computed(() => {
      if (props.type === 'noInitData') {
        return;
      }

      const colorsByType = {
        late: '#FF7B00',
        inTime: '#B6BDC4',
        upcoming: '#FFFFFF',
        completed: '#B6BDC4',
      };

      return colorsByType[props.type];
    });

    return {
      color,
    };
  },
});
