import debounce from 'lodash/debounce';
import { onBeforeUnmount, onMounted, Ref } from 'vue';

export function useElementEventDebounce(
  refElement: Ref<HTMLElement | Document | undefined>,
  eventName: string,
  callback: (event?: Event) => void,
  milliseconds = 50,
): void {
  const callbackDebounce = debounce(callback, milliseconds);

  onMounted(() => {
    refElement.value?.addEventListener(eventName, callbackDebounce);
  });

  onBeforeUnmount(() => {
    refElement.value?.removeEventListener(eventName, callbackDebounce);
  });
}
