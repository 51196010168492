
import { computed, defineComponent } from 'vue';
import FIconCheck from '@/components/UI/Icons/FIconCheck.vue';

export default defineComponent({
  name: 'FCheckbox',
  components: {
    FIconCheck,
  },
  emits: ['change', 'update:modelValue'],
  props: {
    disabled: Boolean,
    modelValue: Boolean,
  },
  setup(props, { emit, slots }) {
    const hasIconSlot = computed(() => !!slots.icon);

    function toggleCheckbox() {
      emit('update:modelValue', !props.modelValue);
      emit('change', !props.modelValue);
    }

    return {
      toggleCheckbox,
      hasIconSlot,
    };
  },
});
