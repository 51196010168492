import { createI18n } from 'vue-i18n';
import commonMessages from '@/i18n/commonMessages.json';

export const i18n = createI18n({
  legacy: false,
  locale: 'ru',
  messages: commonMessages,
  fallbackLocale: 'ru',
  missing: () => '',
  missingWarn: false,
});
