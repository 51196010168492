import { GlossaryItemsLabelsByValue, GlossaryItemLabels } from '@/stores/almanac';

export function convertGlossaryValuesToLabelString(
  value?: string | string[],
  labelsByValue?: GlossaryItemsLabelsByValue,
  isShortLabels = false,
): string | undefined {
  if (!labelsByValue || !value) {
    return '';
  }

  function getLabel(option?: GlossaryItemLabels) {
    if (!option) {
      return '';
    }

    return isShortLabels ? option.shortLabel || option.label : option.label;
  }

  return Array.isArray(value)
    ? value
        .map(type => getLabel(labelsByValue[type]))
        .filter(type => type)
        .join(', ')
    : getLabel(labelsByValue[value]);
}
