import { ref } from 'vue';

export const useBoolean = (defaultValue?: boolean) => {
  const value = ref(defaultValue || false);

  const setTrue = () => {
    value.value = true;
  };

  const setFalse = () => {
    value.value = false;
  };

  return [value, setTrue, setFalse] as const;
};
