import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, Teleport as _Teleport, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ccc02ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "f-select-validation-label"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["id"]
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = {
  key: 1,
  class: "f-select-popover-content"
}
const _hoisted_14 = {
  key: 0,
  class: "f-select-options"
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["checked"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 0,
  class: "f-select-limit-label"
}
const _hoisted_19 = {
  key: 1,
  class: "f-select-not-found-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon_circle_close = _resolveComponent("f-icon-circle-close")!
  const _component_f_spinner = _resolveComponent("f-spinner")!
  const _component_f_icon_close = _resolveComponent("f-icon-close")!
  const _component_f_icon_arrow_down_flat = _resolveComponent("f-icon-arrow-down-flat")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["f-select", {
      'f-select_opened': _ctx.optionsVisible,
      'f-select_with-value': _ctx.hasValue,
      'f-select_warning': _ctx.warning,
      'f-select_error': _ctx.error,
      'f-select_disabled': !_ctx.canOptionsShow,
      'f-select_text-overflow': !_ctx.valueTextOverflowDisabled,
      'f-select_with-filter': !!_ctx.filter,
      'f-select_with-float-label': _ctx.floatLabel,
    }]),
    id: _ctx.id,
    ref: "selectOptionsDOM",
    onKeyup: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.hidePopover && _ctx.hidePopover(...args)), ["esc"])),
    onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.canOptionsShow && _ctx.showPopover()), ["stop"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["f-select-inner", { active: _ctx.optionsVisible || _ctx.hasValue }])
    }, [
      (_ctx.floatLabel)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["f-select-float-label", { uppercase: _ctx.labelUppercase }]),
            innerHTML: _ctx.placeholderLabel
          }, null, 10, _hoisted_2))
        : (!_ctx.placeholderHidden && !_ctx.hasValue && _ctx.placeholderLabel)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["f-select-placeholder", { uppercase: _ctx.labelUppercase }]),
              innerHTML: _ctx.placeholderLabel
            }, null, 10, _hoisted_3))
          : _createCommentVNode("", true),
      (!_ctx.multiple && _ctx.valueLabel)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: "f-select-value",
            innerHTML: _ctx.valueLabel
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.multiple && _ctx.multipleValueLabels && _ctx.multipleValueLabels.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["f-select-values", { 'f-select-values_overflow': _ctx.overflow }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.multipleValueLabels, (itemLabel, idx) => {
              return (_openBlock(), _createElementBlock("span", {
                key: idx,
                class: _normalizeClass(["f-select-multiple-values-item", { 'f-select-multiple-values-item_chip': _ctx.chips }])
              }, [
                _createElementVNode("span", { innerHTML: itemLabel }, null, 8, _hoisted_5),
                (!_ctx.multipleValueCommaDivider)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "f-select-multiple-values-item-clear",
                      onClick: _withModifiers(($event: any) => (_ctx.canOptionsShow && _ctx.removeMultipleValueItem(idx)), ["stop"])
                    }, [
                      _createVNode(_component_f_icon_circle_close, { class: "f-select-icon f-select-icon_circle" })
                    ], 8, _hoisted_6))
                  : (idx !== _ctx.multipleValueLabels.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, ", "))
                    : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_f_spinner, {
            key: 4,
            class: "f-select-loading",
            size: "24"
          }))
        : (!_ctx.clearButtonHidden)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
              (!_ctx.multiple && _ctx.localValue)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "f-select-icon-container",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.canOptionsShow && _ctx.clearValue()), ["stop"]))
                  }, [
                    _createVNode(_component_f_icon_close, {
                      class: "f-select-icon f-select-icon_close",
                      color: "#151616"
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "f-select-icon-container",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.canOptionsShow && _ctx.showPopover()), ["stop"]))
                  }, [
                    _createVNode(_component_f_icon_arrow_down_flat, { class: "f-select-icon f-select-icon_down" })
                  ]))
            ], 64))
          : _createCommentVNode("", true)
    ], 2),
    (_ctx.warningLabel || _ctx.errorLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_ctx.warning && _ctx.warningLabel)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "f-select-warning-label",
                innerHTML: _ctx.warningLabel
              }, null, 8, _hoisted_9))
            : _createCommentVNode("", true),
          (_ctx.error && _ctx.errorLabel)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "f-select-error-label",
                innerHTML: _ctx.errorLabel
              }, null, 8, _hoisted_10))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.optionsVisible)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 1,
          to: "#popover"
        }, [
          _withDirectives((_openBlock(), _createElementBlock("div", {
            id: _ctx.id ? `${_ctx.id}-popover` : '',
            ref: "selectOptionsPopoverDOM",
            class: _normalizeClass(["f-select-options-popover", {
          'f-select-options-popover_with-filter': _ctx.filter,
          [_ctx.popoverClass]: _ctx.popoverClass,
        }]),
            onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.inputFilter && _ctx.inputFilter(...args)))
          }, [
            (_ctx.filter)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.optionsLoading)
                    ? (_openBlock(), _createBlock(_component_f_spinner, {
                        key: 0,
                        class: "f-select-filter-loading",
                        size: "24"
                      }))
                    : _createCommentVNode("", true),
                  _withDirectives(_createElementVNode("input", {
                    class: "f-select-filter",
                    ref: "filterInputDOM",
                    type: "text",
                    placeholder: _ctx.filterPlaceholder || 'Фильтр',
                    autocomplete: "off",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterValue) = $event))
                  }, null, 8, _hoisted_12), [
                    [_vModelText, _ctx.filterValue]
                  ]),
                  (!_ctx.optionsLoading)
                    ? (_openBlock(), _createBlock(_component_f_icon_arrow_down_flat, {
                        key: 1,
                        class: "f-select-filter-icon"
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (!_ctx.optionsLoading && !_ctx.loadOptionsWaiting)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  (_ctx.selectOptions.length)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (option, idx) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: idx,
                            class: _normalizeClass(["f-select-option", {
                  'f-select-option_active': _ctx.checkOptionIsActive(option),
                  'f-select-option_disabled': option.disabled,
                }]),
                            onClick: _withModifiers(($event: any) => (!option.disabled && _ctx.selectOption(option)), ["stop"])
                          }, [
                            (_ctx.multiple)
                              ? (_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  type: "checkbox",
                                  class: "f-select-option-checkbox",
                                  checked: _ctx.checkOptionIsActive(option)
                                }, null, 8, _hoisted_16))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", {
                              class: _normalizeClass(["f-select-option-label", option.className]),
                              innerHTML: option.label
                            }, null, 10, _hoisted_17)
                          ], 10, _hoisted_15))
                        }), 128)),
                        (
                _ctx.filter &&
                _ctx.filteredItemsVisibleLimit !== false &&
                _ctx.localOptionsTotalCount > _ctx.filteredItemsVisibleLimit &&
                !_ctx.limitLabelHidden
              )
                          ? (_openBlock(), _createElementBlock("li", _hoisted_18, _toDisplayString(_ctx.limitLabel ||
                `Еще элементов: ${
                  _ctx.localOptionsTotalCount - _ctx.filteredItemsVisibleLimit
                }. Уточните поиск`), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : (_ctx.filter && (!_ctx.emptyLabelIfEmptyFilterHidden || _ctx.filterValue))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.notFoundLabel || 'Ничего не найдено'), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 42, _hoisted_11)), [
            [_directive_click_outside, _ctx.hidePopover]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}