import { Directive } from 'vue';

export const clickOutside: Directive = {
  mounted(el, binding) {
    el.clickOutSideEvent = (event: MouseEvent) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };

    window.addEventListener('click', el.clickOutSideEvent);
  },
  beforeUnmount(el) {
    window.removeEventListener('click', el.clickOutSideEvent);
  },
};
