// Основная обертка все запросов во все API
// Реализует централизованную обработку ошибок от сервера
// А также индикацию процесса ожидания ответа от сервера

import { AxiosInstance, AxiosRequestConfig } from 'axios';

export type ApiWrapperErrors = {
  [name: string]: (err?: Error) => void | string;
};

// Стандартная обработка ошибок.
// Можно перекрыть кастомной обработке
// при вызове apiWrapper;
const defaultErrors: ApiWrapperErrors = {
  500: err => {
    console.warn('API Error: server returned 500', err);
  },
  502: err => {
    console.warn('API Error: server is down (502)', err);
  },
  // 401: err => {
  //   console.log('Uf, 401');
  // },
};

type GraphqlResponseError = {
  message?: string;
};

type GraphqlResponse<T> = {
  data: T;
} & {
  errors?: Array<{ message: string }>;
};

export const apiGraphqlCallWrapper = async <T, V = undefined>(
  api: AxiosInstance,
  axiosOptions: Omit<AxiosRequestConfig, 'data'> & { data: { query: string; variables?: V } },
  options?: {
    // Бэк может нам вернуть ошибки graphql прямо с ответом
    onResponseErrors?: (errors?: GraphqlResponseError[]) => void;
    // А это вариант ошибок по коду
    errorsByCode?: ApiWrapperErrors;
    // При любой ошибке (юзается при начале интеграции, или сбросе данных)
    onAnyError?: () => void;
  },
): Promise<T | undefined> => {
  let result;

  try {
    const res = await api.request<GraphqlResponse<T>>({ method: 'post', ...axiosOptions });
    const data = res?.data;

    if (data?.errors?.length) {
      // Ошибки с 200-ым ответом
      if (options?.onAnyError) {
        // Общий callback для обоих типов ошибок
        options.onAnyError();
      }

      if (options?.onResponseErrors) {
        // Обработчик ошибок по массиву errors
        options.onResponseErrors(data.errors);
      }
    } else {
      result = data?.data;
    }
  } catch (err) {
    // Ошибки по коду
    const errors: ApiWrapperErrors = {
      ...defaultErrors,
      ...options?.errorsByCode,
    };

    if (err.response) {
      if (options?.onAnyError) {
        // Общий callback для обоих типов ошибок
        options.onAnyError();
      }

      // Обработчики ошибок по коду
      if (errors[err.response.status]) {
        if (typeof errors[err.response.status] === 'function') {
          errors[err.response.status](err);
        } else if (typeof errors[err.response.status] === 'string') {
          console.warn(`API ERROR! ${errors[err.response.status]}`);
        }
      }
    }
  }

  return result;
};

export const apiCallWrapper = async <T>(
  api: AxiosInstance,
  axiosOptions: AxiosRequestConfig,
  customErrors?: ApiWrapperErrors,
): Promise<T | undefined> => {
  let result;
  try {
    // ASYNC SLEEP для тестов, можно временно вместо apiCall  ставить.
    // await new Promise(r => setTimeout(r, 5000));
    // const res = { data: {} as T };
    const res = await api.request<T>(axiosOptions);

    if (res.data) {
      result = res.data;
    }
  } catch (err) {
    const errors: ApiWrapperErrors = {
      ...defaultErrors,
      ...customErrors,
    };

    if (errors.common) {
      errors.common(err);
    }

    if (err.response) {
      if (errors[err.response.status]) {
        if (typeof errors[err.response.status] === 'function') {
          errors[err.response.status](err);
        } else if (typeof errors[err.response.status] === 'string') {
          console.warn(`API ERROR! ${errors[err.response.status]}`);
        }
      } else if (errors.default) {
        errors.default(err);
      }
    } else if (errors.default) {
      errors.default(err);
    }
  } finally {
    // Вариант: поставим небольшую задержку, так как после прихода
    // ответа ещё какое-то время тратится на обработку этого ответа.
    //
    // setTimeout(() => {
    //   storeMain.turnOffLoading(loadingName);
    // }, 300);
  }

  return result;
};

// Обертка для web-gRPC.
// export const callGRPCWrapper = async (): Promise<void> => {};
