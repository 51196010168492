import { useAuthStore } from '@/stores/auth';
import { useProfileStore } from '@/stores/profile';

export async function loggedInGuard(): Promise<boolean> {
  let logged = false;
  const profileStore = useProfileStore();
  const authStore = useAuthStore();
  const { accessToken } = authStore;

  if (accessToken) {
    if (!authStore.isAuthenticated) {
      // Не авторизованы, но есть токен. Пробуем авторизоваться по нему
      await authStore.getAuthMeData();
    }

    if (authStore.isAuthenticated && !profileStore.profile?.id) {
      // Авторизованы, но нет профиля. Получаем
      await profileStore.getProfile();
    }

    if (authStore.isAuthenticated && profileStore.profile && profileStore.profile.id) {
      // Авторизованы и получили профиль.
      logged = true;
    }
  }

  return logged;
}
