import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: "29",
    viewBox: "0 0 90 29",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M4.9254 28.3942H0V0H17.4625V4.73244H4.9254V11.812H17.4625V16.5443H4.9254V28.3942ZM80.3245 0H75.1755L65.5376 28.3942H70.7242L77.7502 7.26885L84.8134 28.3942H90L80.3245 0ZM40.1591 0H35.2337V19.4216C35.2337 22.3368 33.1442 24.3433 30.3083 24.3433C27.4728 24.3433 25.3832 22.3368 25.3832 19.4216V0H20.4578V19.3459C20.4578 25.0626 24.8609 29 30.3083 29C35.7561 29 40.1591 25.0626 40.1591 19.3459V0ZM64.375 22.9172V28.3942L48.9648 21.4282V28.3942H44.0394V0H55.4573C60.2332 0 64.375 3.06661 64.375 8.17762C64.375 13.2886 60.2332 16.3929 55.4573 16.3929H49.4124L64.375 22.9172ZM55.569 11.6606C57.6961 11.6606 59.4496 10.4869 59.4496 8.17762C59.4496 5.83024 57.6961 4.73244 55.569 4.73244H48.9648V11.6606H55.569Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}