import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification';
import { apiGraphqlCallWrapper } from './apiWrapper';
import {
  GetGlossaries,
  GetGlossariesQuery,
  GetGlossariesQueryVariables,
  Glossary,
  GlossaryElement,
} from '@/api/generated/portal_api';
import { apiTrace } from '@/api/trace';
import { print } from 'graphql';

const toast = useToast();

const GLOSSARY_IDS = ['cargoTypes', 'vehicleTypes', 'bodyTypes'];

// Типы словарей, которые приходят с бэка

type GlossaryItemContent = {
  label?: string;
  title?: string;
  shortLabel?: string;
  value: string;
};

export type GlossaryItemLabels = {
  label?: string;
  shortLabel?: string;
};

export type GlossaryItemsLabelsByValue = Record<string, GlossaryItemLabels>;

type GlossaryWithLabelByValue = {
  elements: GlossaryElement[];
  labelsByValue: GlossaryItemsLabelsByValue;
};

export type ParsedGlossaries = Record<string, GlossaryWithLabelByValue>;

type State = {
  glossaries: ParsedGlossaries;
  glossariesLoading: boolean;
};

export const useAlmanacStore = defineStore({
  id: 'almanac',
  state: (): State => ({
    glossaries: {},
    glossariesLoading: false,
  }),
  actions: {
    setGlossariesFromRawGlossaries(rawGlossaries: Glossary[]) {
      this.glossaries = {};
      rawGlossaries.forEach(glossary => {
        const { id, elements } = glossary;
        this.glossaries[id] = {
          elements: glossary.elements || [],
          labelsByValue: {},
        };

        elements.forEach(element => {
          const { value, title, label, shortLabel } = element.content as GlossaryItemContent;
          this.glossaries[id].labelsByValue[value] = {
            // Так уж вышло, что в словаре заголовок может быть внутри content как
            // label, title, так и за пределами. Беру в приоритете из content.
            // TODO: Подумать что с этим делать
            label: label || title || element.title,
            shortLabel,
          };
        });
      });
    },
    async getCommonGlossaries() {
      this.glossariesLoading = true;
      const rawGlossaries = await apiGraphqlCallWrapper<
        GetGlossariesQuery,
        GetGlossariesQueryVariables
      >(
        apiTrace,
        {
          url: 'query',
          data: {
            query: print(GetGlossaries),
            variables: {
              ids: GLOSSARY_IDS,
            },
          },
        },
        {
          onAnyError: () => {
            toast.error('Не удалось загрузить словари');
          },
        },
      );

      this.glossariesLoading = true;
      if (rawGlossaries) {
        this.setGlossariesFromRawGlossaries(rawGlossaries.glossaries);
      }
      this.glossariesLoading = false;
    },
  },
});
