
import { defineComponent, PropType } from 'vue';
import FButtonContent from './_partials/FButtonContent.vue';
import { RouteLocation } from 'vue-router';

export default defineComponent({
  name: 'FButton',
  components: { FButtonContent },
  props: {
    to: Object as PropType<RouteLocation>,
  },
});
