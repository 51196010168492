import { Directive } from 'vue';

const fUiClickOutside: Directive = {
  mounted(el, binding) {
    const appDOM = document.querySelector('#app');

    el.clickOutSideEvent = (event: MouseEvent) => {
      const { handler } = binding.value;

      if (appDOM && !appDOM.contains(event.target as HTMLElement)) {
        return;
      }

      if (!el.contains(event.target)) {
        handler(event);
      }
    };

    setTimeout(() => {
      document.addEventListener('click', el.clickOutSideEvent);
    }, 0);
  },
  beforeUnmount(el) {
    document.removeEventListener('click', el.clickOutSideEvent);
  },
};

export default fUiClickOutside;
