
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  PropType,
  ref,
  watch,
} from 'vue';
import debounce from 'lodash/debounce';
import { useI18n } from 'vue-i18n';
import HeaderContainer from '@/components/HeaderContainer.vue';
import RequestsHeader from '@/components/RequestsHeader.vue';
import RequestsTable from '@/components/RequestsTable.vue';
import { TransportationsSummaryFragment } from '@/api/generated/portal_api';
import { ShortsTransportationsWithCount } from '@/stores/transportations';
import RequestsTabs from '@/components/RequestsTabs.vue';
import { ParsedGlossaries } from '@/stores/almanac';
import { TransportationsRouteTypes } from '@/router/constants';
import { UserProfileFragment } from '@/api/generated/portal_api';

const ENABLE_RELOAD_SCROLL_POS = 30;
const DISABLE_RELOAD_SCROLL_POS = 250;

export default defineComponent({
  name: 'Requests',
  components: {
    RequestsTabs,
    RequestsHeader,
    RequestsTable,
    HeaderContainer,
  },
  props: {
    transportationsLoading: Boolean,
    transportationsCountLoading: Boolean,
    transportationsCountByType: {
      type: Object as PropType<TransportationsSummaryFragment>,
    },
    glossaries: {
      type: Object as PropType<ParsedGlossaries>,
      required: true,
    },
    transportations: {
      type: Object as PropType<ShortsTransportationsWithCount>,
    },
    loadTransportations: {
      type: Function,
      required: true,
    },
    loadTransportationsCount: {
      type: Function,
      required: true,
    },
    silentReloadTransportationsCount: {
      type: Function,
      required: true,
    },
    silentReloadTransportations: {
      type: Function,
      required: true,
    },
    transportationsSilentReloading: Boolean,
    type: {
      type: String as PropType<TransportationsRouteTypes>,
      required: true,
    },
    profile: {
      type: Object as PropType<UserProfileFragment>,
    },
  },
  setup(props) {
    const scrolledByChangingType = ref(false);
    const scrollPosY = ref<number>(0);
    const reloadOnScrollDisabled = ref(false);
    const isFinishedTransportations = computed(
      () => props.type === TransportationsRouteTypes.completed,
    );

    function onScroll() {
      if (window.scrollY > DISABLE_RELOAD_SCROLL_POS) {
        reloadOnScrollDisabled.value = true;
      }
      if (
        !scrolledByChangingType.value &&
        reloadOnScrollDisabled.value &&
        window.scrollY < ENABLE_RELOAD_SCROLL_POS
      ) {
        // Проскроллили наверх к значению скролла, в котором запускается таймер перегрузки заявок.
        // Поэтому перегружаем заявки сразу.
        reloadOnScrollDisabled.value = false;
        props.silentReloadTransportations();
        props.silentReloadTransportationsCount();
      }

      scrolledByChangingType.value = false;
      scrollPosY.value = window.scrollY;
    }

    const onScrollDebounce = debounce(onScroll, 500);

    onBeforeMount(() => {
      document.addEventListener('scroll', onScrollDebounce);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('scroll', onScrollDebounce);
    });

    const loadTransportationsAndCount = () => {
      props.loadTransportationsCount();
      props.loadTransportations();
    };

    onBeforeMount(loadTransportationsAndCount);

    watch(
      () => props.type,
      () => {
        // Изменился тип заявки.
        // Скроллим наверх и ставим флаг, чтобы отличать пользовательский скролл
        scrolledByChangingType.value = true;
        window.scrollTo(0, 0);
        loadTransportationsAndCount();
      },
    );

    const { t } = useI18n({
      messages: {
        ru: {},
      },
    });

    return {
      t,
      TransportationsRouteTypes,
      isFinishedTransportations,
    };
  },
});
