import { onMounted, onUnmounted } from 'vue';

export function useDocumentKeyDown(key: string, callback: (event: KeyboardEvent) => void): void {
  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === key) {
      callback(event);
    }
  }

  onMounted(() => {
    document.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', handleKeyDown);
  });
}
