import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b2a3d1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "targets" }
const _hoisted_2 = { class: "targetType" }
const _hoisted_3 = {
  key: 0,
  class: "targetJurTitle"
}
const _hoisted_4 = { class: "targetJurAddress" }
const _hoisted_5 = {
  key: 1,
  class: "beingLate"
}
const _hoisted_6 = { class: "targetDates" }
const _hoisted_7 = { class: "targetDate" }
const _hoisted_8 = { class: "targetDateLabel" }
const _hoisted_9 = { class: "targetDateTime" }
const _hoisted_10 = { class: "targetDate" }
const _hoisted_11 = { class: "targetDateLabel" }
const _hoisted_12 = { class: "targetDateLabel" }
const _hoisted_13 = {
  key: 2,
  class: "updatedBy"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_target_actual_date = _resolveComponent("edit-target-actual-date")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transportation.targets, (target, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "target",
        key: index
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["targetInfo", {
          passed: target.targetStatusDetails.targetStatus === _ctx.TargetStatus.TargetStatusCompleted,
        }])
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(index + 1) + ". " + _toDisplayString(target.type === _ctx.TargetType.TargetTypeLoading ? _ctx.t('loading') : _ctx.t('unloading')), 1),
          (target.consignee)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(target.consignee), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_4, [
            (target.address.formatted)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(target.address.formatted), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          (_ctx.checkTargetDelayVisible(target))
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t('beingLateFor')) + " " + _toDisplayString(_ctx.delayToHumanText(target.delay)), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('plannedDate')), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formatDateToHumanTextWithTime(target.plannedArriveAtLocal, true)), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (
              (target.targetStatusDetails.targetStatus === _ctx.TargetStatus.TargetStatusCompleted ||
                target.targetStatusDetails.targetStatus ===
                  _ctx.TargetStatus.TargetStatusDriverArrived) &&
              target.actualArriveAtLocal
            )
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('factDate')), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["targetDateTime", {
                orange: target.driverWasLate,
              }])
                  }, _toDisplayString(_ctx.formatDateToHumanTextWithTime(target.actualArriveAtLocal, true)), 3)
                ], 64))
              : (
              target.id === _ctx.transportation.currentTarget?.id && target.estimatedArriveAtLocal
            )
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('actualDate')), 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["targetDateTime", { orange: target.driverIsLate }])
                    }, _toDisplayString(_ctx.formatDateToHumanTextWithTime(target.estimatedArriveAtLocal, true)), 3)
                  ], 64))
                : _createCommentVNode("", true),
            (target.targetStatusDetails.updatedBy)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString([
                target.targetStatusDetails.updatedBy.fullName,
                _ctx.formatDateToHumanTextWithTime(target.targetStatusDetails.updatedAt),
              ]
                .filter(item => item)
                .join(', ')), 1))
              : _createCommentVNode("", true)
          ]),
          (
            (_ctx.isFuraExpeditor || _ctx.isAdmin) &&
            (target.id === _ctx.transportation.currentTarget.id ||
              target.targetStatusDetails.targetStatus === _ctx.TargetStatus.TargetStatusCompleted)
          )
            ? (_openBlock(), _createBlock(_component_edit_target_actual_date, {
                key: 0,
                localActualDate: target.actualArriveAtLocal,
                transportationId: _ctx.transportation.id,
                targetId: target.id
              }, null, 8, ["localActualDate", "transportationId", "targetId"]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}