
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FIconClose',
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: Number,
      default: 12,
    },
  },
});
