
import { defineComponent, computed, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ParsedGlossaries } from '@/stores/almanac';
import RowLabelValue from '@/components/requests/RowLabelValue.vue';
import { convertGlossaryValuesToLabelString } from '@/utils/convertGlossaryValuesToLabelString';
import { TransportationFragment } from '@/api/generated/portal_api';
import { round } from '@/utils/round';

export default defineComponent({
  name: 'Cargo',
  components: {
    RowLabelValue,
  },
  props: {
    transportation: {
      type: Object as PropType<TransportationFragment>,
      required: true,
    },
    glossaries: {
      type: Object as PropType<ParsedGlossaries>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        ru: {
          type: 'Тип',
          numberOfPallets: 'Кол-во паллет',
          volume: 'Объем',
          weight: 'Вес',
          pallet: 'паллет',
          tons: 'тонн',
          none: 'не указан',
        },
      },
    });

    const cargoType = computed(() =>
      convertGlossaryValuesToLabelString(
        props.transportation.request.cargo.cargoType,
        props.glossaries.cargoTypes.labelsByValue,
      ),
    );

    return {
      t,
      convertGlossaryValuesToLabelString,
      round,
      cargoType,
    };
  },
});
