import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    viewBox: "0 0 12 12",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M6.00002 5.29295L10.6465 0.646484L11.3536 1.35359L6.70713 6.00006L11.3536 10.6465L10.6465 11.3536L6.00002 6.70716L1.35359 11.3536L0.646484 10.6465L5.29291 6.00006L0.646486 1.35367L1.35359 0.64656L6.00002 5.29295Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}