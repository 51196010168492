import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-331d2ad5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size,
    height: _ctx.size,
    viewBox: "25 25 50 50",
    class: "f-spinner"
  }, [
    _createElementVNode("circle", {
      cx: "50",
      cy: "50",
      r: "20",
      fill: "none",
      stroke: _ctx.color,
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      class: "f-spinner-path"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}