import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "f-ui-tooltip",
    ref: "tooltipContainerDOM",
    onMousemove: _cache[2] || (_cache[2] = () => _ctx.setPopoverVisible(true)),
    onMouseleave: _cache[3] || (_cache[3] = () => _ctx.setPopoverVisible(false))
  }, [
    (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
      _createVNode(_Transition, { name: "f-ui-fade" }, {
        default: _withCtx(() => [
          (_ctx.visible)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["f-ui-tooltip-popover fixed", `f-ui-tooltip-popover__${_ctx.position}`]),
                ref: "tooltipBlockDOM",
                style: _normalizeStyle({
              left: _ctx.left + 'px',
              top: _ctx.top + 'px',
              marginLeft: _ctx.marginLeft + 'px',
              marginTop: _ctx.marginTop + 'px',
            }),
                onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseOverTooltipPopover && _ctx.handleMouseOverTooltipPopover(...args))),
                onMouseleave: _cache[1] || (_cache[1] = () => _ctx.setPopoverVisible(false))
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 38))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]))
  ], 544))
}