
import { defineComponent, onBeforeUnmount, onMounted, PropType, ref } from 'vue';
import debounce from 'lodash/debounce';

type Position = 'top' | 'right' | 'left';

export default defineComponent({
  name: 'FTooltip',
  props: {
    delay: {
      type: Number,
      default: 0,
    },
    position: {
      type: String as PropType<Position>,
      default: 'top',
    },
  },
  setup(props) {
    const tooltipContainerDOM = ref<HTMLInputElement>();
    const tooltipBlockDOM = ref<HTMLInputElement>();
    const visible = ref(false);
    const marginLeft = ref(0);
    const marginTop = ref(0);
    const left = ref(0);
    const top = ref(0);
    const timerDelay = ref(0);

    const setPosition = () => {
      if (!tooltipContainerDOM.value || !tooltipBlockDOM.value) {
        return;
      }

      const { offsetWidth } = tooltipBlockDOM.value;
      const rect = tooltipContainerDOM.value.getBoundingClientRect();

      if (props.position === 'top') {
        left.value = rect.left + rect.width / 2;
        top.value = rect.top - 8;
        marginLeft.value =
          left.value + offsetWidth > window.innerWidth - 32
            ? left.value + offsetWidth - (window.innerWidth - 32)
            : 0;
      } else if (props.position === 'right') {
        left.value = rect.left + rect.width;
        top.value = rect.top + rect.height / 2;
      } else if (props.position === 'left') {
        left.value = rect.left - offsetWidth;
        top.value = rect.top + rect.height / 2;
      }
    };

    const setPopoverVisible = (value: boolean) => {
      if (visible.value === value) {
        return;
      }
      timerDelay.value = setTimeout(() => {
        visible.value = value;
        if (visible.value) {
          setTimeout(() => {
            setPosition();
          }, 0);
        }
      }, props.delay);
    };

    const handleMouseOverTooltipPopover = () => {
      clearTimeout(timerDelay.value);
    };

    const hideTooltipDebounced = debounce(() => setPopoverVisible(false), 10);

    onMounted(() => {
      document.addEventListener('scroll', hideTooltipDebounced);
    });

    onBeforeUnmount(() => {
      document.addEventListener('scroll', hideTooltipDebounced);
    });

    return {
      visible,
      marginLeft,
      marginTop,
      tooltipContainerDOM,
      tooltipBlockDOM,
      setPosition,
      setPopoverVisible,
      left,
      top,
      handleMouseOverTooltipPopover,
    };
  },
});
