import { AxiosInstance } from 'axios';
import config from '@/../config/config';
import { registerAPI } from './API';

export const apiTrace: AxiosInstance = registerAPI('trace', {
  commonAuth: true,
  config: {
    baseURL: config.app.url.apiTrace,
  },
});
