
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { Target, TransportationFragment } from '@/api/generated/portal_api';
import { ParsedGlossaries } from '@/stores/almanac';
import { formatDateToHumanTextWithTime } from '@/utils/date';
import { TargetType, TargetStatus, TraceStatus } from '@/api/generated/portal_api';
import { delayToHumanText } from '@/utils/delayToHumanText';
import { useBoolean } from '@/hooks/useBoolean';
import EditTargetActualDate from '@/components/requests/EditTargetActualDate.vue';
import { useUserRoles } from '@/hooks/useUserRoles';

export default defineComponent({
  name: 'Monitoring',
  components: { EditTargetActualDate },
  props: {
    transportation: {
      type: Object as PropType<TransportationFragment>,
      required: true,
    },
    glossaries: {
      type: Object as PropType<ParsedGlossaries>,
      required: true,
    },
  },
  setup() {
    const [editFactDateVisible, openEditFactDate, closeEditFactDate] = useBoolean();
    const { isFuraExpeditor, isAdmin } = useUserRoles();

    function checkTargetDelayVisible(target: Target) {
      return (
        (target.targetStatusDetails.targetStatus === TargetStatus.TargetStatusDriverOnWay &&
          target.driverIsLate) ||
        ((target.targetStatusDetails.targetStatus === TargetStatus.TargetStatusDriverArrived ||
          (target.targetStatusDetails.targetStatus === TargetStatus.TargetStatusCompleted &&
            target.driverWasLate)) &&
          target.delay > 0)
      );
    }
    const { t } = useI18n({
      messages: {
        ru: {
          loading: 'Загрузка',
          unloading: 'Разгрузка',
          plannedDate: 'Плановое время',
          actualDate: 'Ожидаемое время',
          factDate: 'Фактическое время',
          beingLateFor: 'Опоздание на',
        },
      },
    });

    return {
      t,
      formatDateToHumanTextWithTime,
      TargetType,
      delayToHumanText,
      TargetStatus,
      TraceStatus,
      checkTargetDelayVisible,
      editFactDateVisible,
      openEditFactDate,
      closeEditFactDate,
      isFuraExpeditor,
      isAdmin,
    };
  },
});
