
import { computed, defineComponent, PropType } from 'vue';
import Header from './Header.vue';
import { TransportationsRouteTypes } from '@/router/constants';
import { useProfileStore } from '@/stores/profile';

export default defineComponent({
  name: 'HeaderContainer',
  components: {
    Header,
  },
  props: {
    type: {
      type: String as PropType<TransportationsRouteTypes>,
      required: true,
    },
  },
  setup() {
    const profileStore = useProfileStore();
    const profile = computed(() => profileStore.profile);

    return {
      profile,
    };
  },
});
