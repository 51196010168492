import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification';
import { apiTrace } from '@/api/trace';
import { apiGraphqlCallWrapper } from './apiWrapper';
import {
  GetTransportation,
  GetTransportationQuery,
  GetTransportations,
  GetTransportationsQuery,
  GetTransportationsSummary,
  GetTransportationsSummaryQuery,
  QueryTransportationsArgs,
  ShortTransportationFragment,
  TransportationFragment,
  TransportationsSummaryFragment,
  TrackFragment,
  GetTransportationsQueryVariables,
  GetTransportationQueryVariables,
  UpdateTargetStatusToCompleted,
  UpdateTargetStatusToCompletedMutation,
  UpdateTargetStatusToCompletedMutationVariables,
} from '@/api/generated/portal_api';
import { print } from 'graphql';

const toast = useToast();

export type ShortsTransportationsWithCount = {
  items: ShortTransportationFragment[];
  after?: string;
  hasNextPage: boolean;
};

type TransportationsFilters = {
  isCompleted?: boolean;
};

type TransportationsParams = {
  after?: string;
  filters?: TransportationsFilters;
};

type State = {
  transportations: ShortsTransportationsWithCount;
  transportationsLoading: boolean;
  transportationUpdating: boolean;
  transportationsSilentReloading: boolean;
  transportationLoading: boolean;
  transportationsCountByType?: TransportationsSummaryFragment;
  transportationsCountLoading: boolean;
  transportationsCountSilentReloading: boolean;
  transportation?: TransportationFragment;
  lastFilters?: TransportationsFilters;
  driverCurrentPosition?: TrackFragment['driverLocation'];
};

export const useTransportationsStore = defineStore({
  id: 'transportations',
  state: (): State => ({
    transportations: {
      items: [],
      after: '',
      hasNextPage: true,
    },
    transportationsLoading: false,
    transportationsSilentReloading: false,
    transportationLoading: false,
    transportationsCountLoading: false,
    transportationsCountByType: undefined,
    transportationsCountSilentReloading: false,
    transportationUpdating: false,
    transportation: undefined,
    lastFilters: undefined,
    driverCurrentPosition: undefined,
  }),
  actions: {
    async baseLoadloadTransportationsCount() {
      const params = {
        url: 'query',
        data: {
          query: print(GetTransportationsSummary),
        },
      };
      const options = {
        onAnyError: () => {
          toast.error('Не удалось загрузить количество перевозок');
        },
      };

      const res = await apiGraphqlCallWrapper<GetTransportationsSummaryQuery>(
        apiTrace,
        params,
        options,
      );
      this.transportationsCountByType = res?.transportationsSummary || undefined;
    },

    async loadTransportationsCount() {
      if (this.transportationsCountLoading || this.transportationsCountSilentReloading) {
        return;
      }
      this.transportationsCountLoading = true;
      await this.baseLoadloadTransportationsCount();
      this.transportationsCountLoading = false;
    },

    async silentReloadTransportationsCount() {
      if (this.transportationsCountSilentReloading || this.transportationsCountLoading) {
        return;
      }
      this.transportationsCountSilentReloading = true;
      await this.baseLoadloadTransportationsCount();
      this.transportationsCountSilentReloading = false;
    },

    async baseLoadTransportations({ after, filters }: TransportationsParams) {
      const { isCompleted = false } = filters || {};
      const variables: QueryTransportationsArgs = {
        completed: isCompleted,
      };
      if (after) {
        variables.after = after;
      }

      const params = {
        url: 'query',
        data: {
          query: print(GetTransportations),
          variables,
        },
      };
      const options = {
        onAnyError: () => {
          toast.error('Не удалось загрузить перевозки');
        },
      };

      const res = await apiGraphqlCallWrapper<
        GetTransportationsQuery,
        GetTransportationsQueryVariables
      >(apiTrace, params, options);

      return res?.transportations;
    },

    async loadTransportations({ after, filters }: TransportationsParams) {
      if (this.transportationsLoading || this.transportationsSilentReloading) {
        return;
      }
      this.transportationsLoading = true;
      this.lastFilters = { ...filters };

      const res = await this.baseLoadTransportations({ after, filters });

      if (res) {
        this.setTransportations(res, after);
      }

      this.transportationsLoading = false;
    },

    async silentReloadTransportations() {
      if (this.transportationsSilentReloading || this.transportationsLoading) {
        return;
      }
      this.transportationsSilentReloading = true;
      const res = await this.baseLoadTransportations({
        after: this.transportations.after,
        filters: this.lastFilters,
      });
      if (res) {
        this.setTransportations(res);
      }
      this.transportationsSilentReloading = false;
    },

    setTransportations(res: GetTransportationsQuery['transportations'], after?: string) {
      if (!res) {
        return;
      }
      const {
        edges,
        pageInfo: { hasNextPage },
      } = res;
      const transportations = edges.map(({ node, cursor }) => ({
        ...node,
        cursor,
      }));

      if (after) {
        this.transportations = {
          items: [...this.transportations.items, ...transportations],
          hasNextPage,
        };
      } else {
        this.transportations = {
          items: transportations,
          hasNextPage,
        };
      }
    },

    async loadTransportation(id: string) {
      this.transportationLoading = true;
      const params = {
        url: 'query',
        data: {
          query: print(GetTransportation),
          variables: {
            id,
          },
        },
      };
      const options = {
        onAnyError: () => {
          toast.error('Не удалось загрузить перевозку');
        },
      };

      const res = await apiGraphqlCallWrapper<
        GetTransportationQuery,
        GetTransportationQueryVariables
      >(apiTrace, params, options);
      this.transportation = res?.transportation || undefined;

      this.driverCurrentPosition = undefined;
      if (this.transportation?.track?.driverLocation?.coordinates) {
        this.driverCurrentPosition = {
          ...this.transportation?.track?.driverLocation,
        };
      }

      this.transportationLoading = false;
    },

    async updateTransportationDate({
      transportationId,
      targetId,
      actualArriveAtLocal,
    }: {
      transportationId: string;
      targetId: string;
      actualArriveAtLocal: string;
    }) {
      this.transportationUpdating = true;
      const res = await apiGraphqlCallWrapper<
        UpdateTargetStatusToCompletedMutation,
        UpdateTargetStatusToCompletedMutationVariables
      >(
        apiTrace,
        {
          url: 'query',
          data: {
            query: print(UpdateTargetStatusToCompleted),
            variables: {
              data: {
                transportationId,
                targetId,
                actualArriveAtLocal,
              },
            },
          },
        },
        {
          onAnyError: () => {
            toast.error('Не удалось обновить перевозку');
          },
        },
      );
      if (res) {
        this.transportation = { ...res.UpdateTargetStatusToCompletedInput };
      }
      this.transportationUpdating = false;
      return res;
    },
  },
});
