// При замене библиотеки с Axios на другую,
// надо пройтись и заменить все API на другие.
import { TOKEN_REFRESH_URL, useAuthStore } from '@/stores/auth';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import config from '@/../config/config';
import router from '@/router';
import { delay } from '@/utils/delay';

/*** =========================== TYPES =========================== */
// В типах самого Axios этого нет, поэтому держим свой словарик.
// UPDATE: а, нет, есть. Не в прямых типах, но в AxiosRequestConfig.method - enum.
// export enum HttpMethods {
//   GET = 'get',
//   POST = 'post',
//   PUT = 'put',
//   DELETE = 'delete',
//   PATCH = 'patch',
//   HEAD = 'head',
//   OPTIONS = 'options',
// }

export type APIMember = {
  instance: AxiosInstance;
  options: APIOptions;
};

export type APIOptions = {
  commonAuth?: boolean;
  config?: AxiosRequestConfig;
};

export type API = {
  getName: () => string;
  getInstance: () => AxiosInstance;
  getOptions: () => APIOptions;
};

/*** =========================== CORE =========================== */
// Реестр всех API
const apiMembers: {
  [name: string]: APIMember;
} = {};

const defaultOptions: APIOptions = {
  config: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CLIENT-VERSION': `${config.app.name}/${config.version}`,
    },
  },
  commonAuth: false,
};

// Получение конкретного инстанса из реестра
export const getAPI = (name: string): API => ({
  getName() {
    return name;
  },
  getInstance() {
    return apiMembers[name].instance;
  },
  getOptions() {
    return apiMembers[name].options;
  },
});

// Регистрация нового API в реестре
export const registerAPI = (name: string, options: APIOptions): AxiosInstance => {
  apiMembers[name] = {
    instance: axios.create({
      ...defaultOptions.config,
      ...options.config,
    }),
    options,
  };

  // Единственная задача - обрабатывать 401 ошибку, чтобы
  // перехватывать доступ, если что.
  apiMembers[name].instance.interceptors.response.use(
    response => response,
    async error => {
      const authStore = useAuthStore();

      if (
        error.config.url !== TOKEN_REFRESH_URL &&
        error.response &&
        error.response.status === 401
      ) {
        if (authStore.refreshToken && !authStore.tokensRefreshing) {
          // Access токен протух, но есть refresh.
          // В настоящий момент мы не обновляем токены (запросов может быть несколько в один момент.
          // Например, список заявок и запрос количества заявок по типам)
          // Другими словами. Одновременно может вызваться два запроса.
          // Важно, чтобы мы сюда попали именно на первом
          const newTokens = await authStore.refreshTokens();

          if (newTokens && newTokens.accessToken) {
            // Токен обновил, повторяем запрос
            error.config.headers['Authorization'] = `Bearer ${newTokens.accessToken}`;
            return axios.request(error.config);
          } else {
            // Токен не удалось обновить. Делаем разлогин и получаем новый гостевой токен на всякий
            authStore.logout();
            authStore.getGuestToken();
            return router.push({ name: 'login' });
          }
        } else if (authStore.tokensRefreshing) {
          // Сюда мы попадем в момент обновления токенов, но при этом на другом запросе.
          // Например, одновременно запущено два запроса - получить список заявок и количество типа заявок.
          // Другими словами. Одновременно может вызваться два запроса.
          // Важно, чтобы мы сюда попали именно на остальных (не на первом)
          // Ждем пока tokensRefreshing не станет false от первого запроса
          while (authStore.tokensRefreshing) {
            await delay(100);
          }

          // Дополнительная задержка на всякий пожарный
          await delay(100);

          // Если все еще авторизованы, то повторяем все запросы
          // Если нет, то ничего не делать, т.к. нас и так выкинет в разлогин

          if (authStore.isAuthenticated) {
            error.config.headers['Authorization'] = `Bearer ${authStore.accessToken}`;
            return axios.request(error.config);
          }
        }
        console.warn('Interceptor error:', error);
      }

      throw error;
    },
  );

  // Если работа с API требует авторизационного токена,
  // то надо его добавлять перед каждым запросом.
  if (options.commonAuth) {
    apiMembers[name].instance.interceptors.request.use(request => {
      const authStore = useAuthStore();

      const AUTH_TOKEN = authStore.isRefreshTokensMode
        ? authStore.refreshToken
        : authStore.accessToken;
      if (AUTH_TOKEN) {
        request.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
      }
      return request;
    });
  }

  return apiMembers[name].instance;
};

// Удаление API из реестра. Not really useful :)
export const unregisterAPI = (name: string): void => {
  delete apiMembers[name];
  return;
};
