
import { defineComponent, computed, ref, onBeforeMount } from 'vue';
import Request from './Request.vue';
import { useTransportationsStore } from '@/stores/transportations';
import { TransportationModalRouteTabs, TransportationsRouteTypes } from '@/router/constants';
import { useAlmanacStore } from '@/stores/almanac';
import { PropType } from 'vue';

export default defineComponent({
  name: 'RequestContainer',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: String as PropType<TransportationModalRouteTabs>,
    type: {
      type: String as PropType<TransportationsRouteTypes>,
      required: true,
    },
  },
  components: {
    Request,
  },
  setup(props) {
    const transportationsStore = useTransportationsStore();
    const almanacStore = useAlmanacStore();
    const transportation = computed(() => transportationsStore.transportation);
    const glossaries = computed(() => almanacStore.glossaries);
    const driverCurrentPosition = computed(() => transportationsStore.driverCurrentPosition);
    const transportationFirstLoading = ref(false);

    function loadTransportation() {
      return transportationsStore.loadTransportation(props.id);
    }

    onBeforeMount(async () => {
      transportationFirstLoading.value = true;
      await loadTransportation();
      transportationFirstLoading.value = false;
    });

    return {
      transportation,
      glossaries,
      TransportationModalRouteTabs,
      driverCurrentPosition,
      loadTransportation,
      transportationFirstLoading,
    };
  },
});
