
import { computed, defineComponent, ref, onMounted, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useAlmanacStore } from '@/stores/almanac';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'App',
  setup() {
    const authStore = useAuthStore();
    const almanacStore = useAlmanacStore();
    const glossariesLoading = computed(() => almanacStore.glossariesLoading);
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const mounted = ref(false);
    const route = useRoute();
    const freeAccess = computed(() => route.meta?.freeAccess);

    onMounted(() => {
      mounted.value = true;
    });

    watch(
      () => isAuthenticated.value,
      () => {
        if (isAuthenticated.value) {
          almanacStore.getCommonGlossaries();
        }
      },
      { immediate: true },
    );

    return {
      mounted,
      glossariesLoading,
      freeAccess,
    };
  },
});
