
import { defineComponent } from 'vue';
import { provide } from 'vue';

export default defineComponent({
  name: 'FTable',
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    provide('sticky', props.sticky);
  },
});
